import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { BaseComponent } from '../../../base/components/base.component';
import { faDownload, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';
import { CommonModule } from '@angular/common';
import { SubHeaderComponent } from '../sub-header/sub-header.component';
import { SiService } from '../../../system-integrator/services/si.service';
import { AuthService } from '../../service/auth.service';
import {
  PlantManagementSolar,
  solarplantResponseList,
  PlantManagementNonSolar,
  non_solarplantResponseList,
  PlantManagementCountData,
  PlantManagementCount,
} from '../../models/plant-management';
import { Router, RouterModule } from '@angular/router';
import { navHeaders } from '../../interfaces/nav-header';
import { TranslateModule } from '@ngx-translate/core';
import { plantInfo } from '../../interfaces/plant-info';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import {
  nonSolarPlantListHeaders,
  non_solarFilterColumns,
  plantManagementHeaderKeys,
  plantManagementplantInfo,
  solarFilterColumns,
  solarPlantListHeaders,
  reportDetails,
} from '../../../system-integrator/constants/si.constants';
import { Subject, Subscription } from 'rxjs';
import { filterColumns } from '../../interfaces/filter-columns';
import { plainObject } from '../../interfaces/plain-obj';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SELECTED_PLANT_ID, USER_KEY } from '../../service/storage.service';
import { BaseUtilService } from '../../../base/services/base-util.service';
import { ExcelDownloadComponent } from '../excel-download/excel-download.component';
import { number } from 'echarts';
@Component({
  selector: 'app-plant-management',
  standalone: true,
  imports: [
    FontAwesomeModule,
    FormsModule,
    HeaderComponent,
    CommonModule,
    TranslateModule,
    SubHeaderComponent,
    RouterModule,
    MatTooltipModule,
    ExcelDownloadComponent,
  ],
  templateUrl: './plant-management.component.html',
  styleUrl: './plant-management.component.scss',
})
export class PlantManagementComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  fileName = reportDetails.fileName;
  sheetName = reportDetails.sheetName;
  plantResponseList!: solarplantResponseList[];
  getTooltipContent(param: string) {
    let tip = '';

    switch (param) {
      case 'plantHealth':
        tip =
          "Health is being calculated based on today'sis data, considering multiple factors such as solar generation, inverter status, battery health, and data logger communication status.";
        break;
      default:
        break;
    }
    return tip;
  }
  pageSize: number = 20;
  customize: boolean = false;
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faDownload = faDownload;
  faRotateRight = faRotateRight;
  @ViewChild('pageSizeHandler') someInput!: ElementRef;
  solarDataSubscription!: Subscription;
  nonSolarDataSubscription!: Subscription;
  solarPlantManagementCountSubscription!: Subscription;
  nonSolarPlantManagementCountSubscription!: Subscription;
  headerKeyMap: plainObject = plantManagementHeaderKeys;
  plantInfo: Array<plantInfo> = [...plantManagementplantInfo];
  filterColumns: Array<filterColumns> = JSON.parse(
    JSON.stringify(solarFilterColumns),
  );
  selectAll: boolean = false;
  selectAllFilters: boolean = false;
  sortOrder: number = 1;
  sortKey: string = '';

  curTab: string = 'solar';
  solarPlantManagementCount: PlantManagementCountData = {
    totalPlants: 0,
    offlinePlants: 0,
    onlinePlants: 0,
    alerts: 0,
    noAlerts: 0,
  };
  nonSolarPlantManagementCount: PlantManagementCountData = {
    totalPlants: 0,
    offlinePlants: 0,
    onlinePlants: 0,
    alerts: 0,
    noAlerts: 0,
  };
  curPlantManagementCount: PlantManagementCountData = {
    totalPlants: 0,
    offlinePlants: 0,
    onlinePlants: 0,
    alerts: 0,
    noAlerts: 0,
  };
  solarPlantManagementData: Array<solarplantResponseList> = [];
  non_solarPlantManagementData: Array<non_solarplantResponseList> = [];
  displayNext: boolean = false;

  si_service: SiService = inject(SiService);
  baseUtilservice: BaseUtilService = inject(BaseUtilService);
  plantHeader: string[] = [];
  nonSolarPlantListHeaders: Array<string> = nonSolarPlantListHeaders;
  solarPlantListHeaders: Array<string> = solarPlantListHeaders;
  navHeaders: Array<navHeaders> = [
    {
      label: this.translateService.instant('solar'),
      val: 'solar',
    },
    {
      label: this.translateService.instant('non_solar'),
      val: 'non_solar',
    },
  ];
  solarContinuationToken: string | null = '';
  non_solarContinuationToken: string | null = '';
  bmsContinuationToken: string | null = '';
  selectedFilters: Array<string> = [];
  solarPlantData: Map<number, Array<solarplantResponseList>> = new Map<
    number,
    Array<solarplantResponseList>
  >();
  copysolarPlantData: Map<number, Array<solarplantResponseList>> = new Map<
    number,
    Array<solarplantResponseList>
  >();
  non_solarPlantData: Map<number, Array<non_solarplantResponseList>> = new Map<
    number,
    Array<non_solarplantResponseList>
  >();
  copynon_solarPlantData: Map<number, Array<non_solarplantResponseList>> =
    new Map<number, Array<non_solarplantResponseList>>();
  quantity: any = signal([]);
  curSolarPage: number = 0;
  curNonSolarPage: number = 0;
  prevSolarPage: number = -1;
  prevNonsolarPage: number = -1;
  copysolarPlantManagementData: Array<solarplantResponseList> = [];
  copynon_solarPlantManagementData: Array<non_solarplantResponseList> = [];
  selectedColumn: string = '';
  apiResponse!: (solarplantResponseList | non_solarplantResponseList)[];
  columns = this.getReportHeaders(this.solarPlantListHeaders);

  headerStyling = {
    s: {
      fill: { fgColor: { rgb: 'FFFF00' } },
      font: { bold: true, color: { rgb: '000000' } },
    },
  };

  getNextPage() {
    const mouseoverEvent = new MouseEvent('mouseover');
    this.spinner.show()
    switch (this.curTab) {
      case 'solar':
        this.prevSolarPage = this.curSolarPage;
        this.curSolarPage++;
        if (this.solarContinuationToken !== null) {
          if (this.curSolarPage > this.solarPlantData.size) {
            this.spinner.hide();
            this.toastr.info('No more records');
            this.displayNext = true;
            this.solarPlantManagementData =
              this.solarPlantData.get(this.prevSolarPage) ?? [];
            if (this.selectedFilters.length < 18) {
              this.confirmHandler(mouseoverEvent);
            }
            this.prevSolarPage = this.solarPlantData.size - 2;
          } else {
            this.resolveAllSubscriptions([this.getSolarData()]);
          }
        }
        if (this.solarContinuationToken == null) {
          if (this.curSolarPage > this.solarPlantData.size) {
            this.spinner.hide();
            this.toastr.info('No more records');
            this.displayNext = true;
          } else {
            if (this.solarPlantData.get(this.curSolarPage) !== undefined) {
              this.solarPlantManagementData =
                this.solarPlantData.get(this.curSolarPage) ?? [];
                this.spinner.hide();
              if (this.selectedFilters.length !== 19) {
                this.confirmHandler(mouseoverEvent);
              }
            } else {
              this.spinner.hide();
              this.toastr.info('No more records');
              this.displayNext = true;
              this.prevSolarPage = this.solarPlantData.size - 2;
            }
          }
        }
        this.apiResponse = this.solarPlantManagementData as (
          | solarplantResponseList
          | non_solarplantResponseList
        )[];
        this.columns = this.getReportHeaders(this.solarPlantListHeaders);
        break;
      case 'non_solar':
        this.prevNonsolarPage = this.curNonSolarPage;
        this.curNonSolarPage++;
        if (this.non_solarContinuationToken !== null) {
          if (this.curNonSolarPage > this.non_solarPlantData.size) {
            this.spinner.hide();
            this.toastr.info('No more records');
            this.displayNext = true;

            this.non_solarPlantManagementData =
              this.non_solarPlantData.get(this.prevNonsolarPage) ?? [];
            if (this.selectedFilters.length < 15) {
              this.confirmHandler(mouseoverEvent);
            }
            this.prevNonsolarPage = this.non_solarPlantData.size - 1;
          } else {
            this.resolveAllSubscriptions([this.getNonSolarData()]);
          }
        }
        if (this.non_solarContinuationToken == null) {
          if (this.curNonSolarPage > this.non_solarPlantData.size) {
            this.spinner.hide();
            this.toastr.info('No more records');
            this.displayNext = true;
          } else {
            if (
              this.non_solarPlantData.get(this.curNonSolarPage) !== undefined
            ) {
              this.non_solarPlantManagementData =
              this.non_solarPlantData.get(this.curNonSolarPage) ?? [];
              if (this.selectedFilters.length !== 15) {
                this.confirmHandler(mouseoverEvent);
              }
            } else {
              this.spinner.hide();
              this.toastr.info('No more records');
              this.displayNext = true;
              this.prevNonsolarPage = this.non_solarPlantData.size - 2;
            }
          }
        }
        this.apiResponse = this.non_solarPlantManagementData as (
          | solarplantResponseList
          | non_solarplantResponseList
        )[];
        this.columns = this.getReportHeaders(this.nonSolarPlantListHeaders);

        break;

      default:
        break;
    }
  }

  getPrevPage() {
    const mouseoverEvent = new MouseEvent('mouseover');
    switch (this.curTab) {
      case 'solar':
        this.curSolarPage = this.prevSolarPage;
        this.prevSolarPage--;

        if (this.solarPlantData.get(this.curSolarPage) !== undefined) {
          if (this.selectedFilters.length != 18) {
            this.confirmHandler(mouseoverEvent);
          } else {
            this.solarPlantManagementData =
              this.copysolarPlantData.get(this.curSolarPage) ?? [];
          }
        } else {
          this.toastr.info('No more records');
          this.displayNext = true;
        }
        this.apiResponse = this.solarPlantManagementData as (
          | solarplantResponseList
          | non_solarplantResponseList
        )[];

        break;
      case 'non_solar':
        this.curNonSolarPage = this.prevNonsolarPage;
        this.prevNonsolarPage--;

        if (this.non_solarPlantData.get(this.curNonSolarPage) !== undefined) {
          if (this.selectedFilters.length < 15) {
            this.non_solarPlantManagementData =
              this.copynon_solarPlantData.get(this.curNonSolarPage) ?? [];
          } else {
            this.non_solarPlantManagementData =
              this.non_solarPlantData.get(this.curNonSolarPage) ?? [];
          }
        } else {
          this.toastr.info('No more records');
          this.displayNext = true;
        }
        this.apiResponse = this.non_solarPlantManagementData as (
          | solarplantResponseList
          | non_solarplantResponseList
        )[];
        break;

      default:
    }
    this.displayNext = false;
  }

  fetchAPIData() {
    this.resolveAllSubscriptions([
      this.getSolarData(),
      this.getNonSolarData(),
      this.getSolarPlantManagementCount(),
      this.getnonSolarPlantManagementCount(),
    ]);
  }
  ngOnInit(): void {
    this.fetchAPIData();
    this.baseUserService.sessionService.startBnWatch(this.router);
  }

  toggleCheckBox() {
    this.isAllCheckboxSelected() == true
      ? (this.selectAll = true)
      : (this.selectAll = false);
  }
  toggleFilterCheckBox(e: Event) {
    this.isAllFilterCheckboxSelected() == true
      ? (this.selectAllFilters = true)
      : (this.selectAll = false);
  }

  toggleSelectAll() {
    switch (this.curTab) {
      case 'solar':
        this.solarPlantManagementData.forEach(
          (x) => (x.isSelected = this.selectAll),
        );
        break;
      case 'non_solar':
        this.non_solarPlantManagementData.forEach(
          (x) => (x.isSelected = this.selectAll),
        );
        break;

      default:
        break;
    }
  }
  toggleSelectAllFilters() {
    this.filterColumns.forEach((x) => (x.isSelected = this.selectAllFilters));
  }

  resetSelectAllFilters() {
    this.filterColumns.forEach((x) => (x.isSelected = false));
  }

  isAllCheckboxSelected() {
    switch (this.curTab) {
      case 'solar':
        return this.solarPlantManagementData.every((x) => x.isSelected);

      case 'non_solar':
        return this.non_solarPlantManagementData.every((x) => x.isSelected);

      default:
        break;
    }
    return true;
  }
  isAllFilterCheckboxSelected() {
    return this.filterColumns.every((x) => x.isSelected);
  }

  //any needs to be changed to interface
  trackByFn(index: number, item: any) {
    return item.icon;
  }

  togglePageSize(event: Event) {
    const input = event.target as HTMLInputElement;
    this.pageSize = +input.value;
    switch (this.curTab) {
      case 'solar':
        this.solarContinuationToken = '';
        this.prevSolarPage = -1;
        this.curSolarPage = 0;
        this.solarPlantData.clear();
        this.resolveAllSubscriptions([this.getSolarData()]);
        break;
        case 'non_solar':
          this.non_solarContinuationToken = '';
          this.prevNonsolarPage = -1;
          this.curNonSolarPage = 0;
          this.non_solarPlantData.clear();
          this.resolveAllSubscriptions([this.getNonSolarData()]);
        break;
      default:
        break;
    }
    this.displayNext = false;
  }

  toggleCustomize() {
    this.customize = !this.customize;
  }

  cancelFilter(e: Event) {
    this.customize = false;
    e.stopPropagation();
  }

  confirmHandler(e: MouseEvent) {
    this.selectedFilters = this.filterColumns
      .filter((item: filterColumns) => item.isSelected)
      .map((item: filterColumns) => item.name);
    let tempHeaders: Array<string> = [];
    switch (this.curTab) {
      case 'solar':
        if (this.selectedFilters.length === 18) {
          this.solarPlantManagementData =
            this.copysolarPlantData.get(this.curSolarPage) ?? [];
          this.solarPlantListHeaders = solarPlantListHeaders;
        } else {
          let tempsolarPlantManagementData: Array<solarplantResponseList> = [];
          let tempsolarplantResponseList: solarplantResponseList = {};
          if (this.copysolarPlantData.get(this.curSolarPage) !== undefined) {
            this.copysolarPlantManagementData =
              this.copysolarPlantData.get(this.curSolarPage) ?? [];
          }
          this.copysolarPlantManagementData.forEach(
            (item: solarplantResponseList) => {
              this.selectedFilters.forEach((filter: string) => {
                if (
                  item[filter as keyof solarplantResponseList] !== undefined
                ) {
                  tempsolarplantResponseList[
                    filter as keyof solarplantResponseList
                  ] = item[filter as keyof object];
                }
              });
              tempsolarplantResponseList.plantId = item.plantId;
              tempsolarplantResponseList.plantName = item.plantName;
              tempsolarplantResponseList.lastUpdated = item.lastUpdated;
              tempsolarPlantManagementData.push(tempsolarplantResponseList);
              tempsolarplantResponseList = {};
            },
          );
          this.solarPlantManagementData = tempsolarPlantManagementData;
          this.apiResponse = tempsolarPlantManagementData as (
            | solarplantResponseList
            | non_solarplantResponseList
          )[];
          tempHeaders = this.selectedFilters;
          this.solarPlantListHeaders = tempHeaders;
          this.columns = this.getReportHeaders(this.solarPlantListHeaders);
          this.solarPlantData.set(
            this.curSolarPage,
            this.solarPlantManagementData,
          );
        }

        break;
      case 'non_solar':
        if (this.selectedFilters.length === 15) {
          this.solarPlantManagementData = this.copysolarPlantManagementData;
          this.solarPlantListHeaders = solarPlantListHeaders;
        } else {
          let tempnon_solarPlantManagementData: Array<non_solarplantResponseList> =
            [];
          let tempnon_solarplantResponseList: non_solarplantResponseList = {};
          if (
            this.copynon_solarPlantData.get(this.curNonSolarPage) !== undefined
          ) {
            this.copynon_solarPlantManagementData =
              this.copynon_solarPlantData.get(this.curSolarPage) ?? [];
          }

          this.copynon_solarPlantManagementData.forEach(
            (item: non_solarplantResponseList) => {
              this.selectedFilters.forEach((filter: string) => {
                if (
                  item[filter as keyof non_solarplantResponseList] !== undefined
                ) {
                  tempnon_solarplantResponseList[
                    filter as keyof non_solarplantResponseList
                  ] = item[filter as keyof object];
                }
              });
              tempnon_solarplantResponseList.plantId = item.plantId;
              tempnon_solarplantResponseList.plantName = item.plantName;
              tempnon_solarplantResponseList.lastUpdated = item.lastUpdated;
              tempnon_solarPlantManagementData.push(
                tempnon_solarplantResponseList,
              );
              tempnon_solarplantResponseList = {};
            },
          );
          this.non_solarPlantManagementData = tempnon_solarPlantManagementData;
          this.apiResponse = tempnon_solarPlantManagementData as (
            | solarplantResponseList
            | non_solarplantResponseList
          )[];
          tempHeaders = this.selectedFilters;
          this.nonSolarPlantListHeaders = tempHeaders;
          this.columns = this.getReportHeaders(this.nonSolarPlantListHeaders);
        }
        break;
      default:
        break;
    }
    this.customize = false;
    e.stopPropagation();
  }

  resetHandler(e?: MouseEvent) {
    switch (this.curTab) {
      case 'solar':
        this.solarPlantListHeaders = solarPlantListHeaders;
        solarFilterColumns.forEach((item: filterColumns) => {
          item.isSelected = true;
        });
        this.filterColumns = solarFilterColumns;
        if (this.solarPlantData.get(this.curSolarPage) !== undefined) {
          this.solarPlantManagementData =
            this.copysolarPlantData.get(this.curSolarPage) ?? [];
        } else {
          this.solarPlantManagementData =
            this.copysolarPlantData.get(this.curSolarPage - 1) ?? [];
        }
        this.apiResponse = this.solarPlantManagementData as (
          | solarplantResponseList
          | non_solarplantResponseList
        )[];
        this.columns = this.getReportHeaders(this.solarPlantListHeaders);
        break;

      case 'non_solar':
        non_solarFilterColumns.forEach((item: filterColumns) => {
          item.isSelected = true;
        });
        this.nonSolarPlantListHeaders = nonSolarPlantListHeaders;
        this.filterColumns = non_solarFilterColumns;

        if (
          this.copynon_solarPlantData.get(this.curNonSolarPage) !== undefined
        ) {
          this.non_solarPlantManagementData =
            this.copynon_solarPlantData.get(this.curNonSolarPage) ?? [];
        } else {
          this.non_solarPlantManagementData =
            this.copynon_solarPlantData.get(this.curNonSolarPage - 1) ?? [];
        }
        this.apiResponse = this.non_solarPlantManagementData as (
          | solarplantResponseList
          | non_solarplantResponseList
        )[];
        this.columns = this.getReportHeaders(this.nonSolarPlantListHeaders);
        break;
    }
    this.customize = false;
    e?.stopPropagation();
  }

  getKeyByValue(object: Object, value: string) {
    for (const key in object) {
      if (object[key as keyof object] == value) {
        return key;
      }
    }
    return '';
  }

  getValue() {}

  setCurrentUI(value: string) {
    this.curTab = value;
    /**
     * While switch plant type, This will reset all the filter applied before
     */
    switch (value) {
      case 'solar':
        this.updatePlantManagementCount(
          this.plantInfo,
          this.solarPlantManagementCount,
        );
        this.curSolarPage = 0;
        this.prevSolarPage = -1;
        this.columns = this.getReportHeaders(this.solarPlantListHeaders);
        this.apiResponse = this.solarPlantManagementData as (
          | solarplantResponseList
          | non_solarplantResponseList
        )[];
        break;
      case 'non_solar':
        this.updatePlantManagementCount(
          this.plantInfo,
          this.nonSolarPlantManagementCount,
        );
        this.curNonSolarPage = 0;
        this.prevNonsolarPage = -1;
        this.columns = this.getReportHeaders(this.nonSolarPlantListHeaders);
        this.apiResponse = this.non_solarPlantManagementData as (
          | solarplantResponseList
          | non_solarplantResponseList
        )[];
        break;
      default:
        break;
    }
    this.displayNext = false;
    this.resetHandler();
    this.customize = false;
    this.sortOrder = 1;
    this.selectedColumn = '';
  }

  updatePlantManagementCount(
    obj: Array<plantInfo>,
    PlantManagementCount: PlantManagementCountData,
  ) {
    obj.forEach((element: plantInfo) => {
      switch (element.description) {
        case 'Total Plants':
          element.count = PlantManagementCount.totalPlants;

          break;
        case 'Online':
          element.count = PlantManagementCount.onlinePlants;

          break;
        case 'Offline':
          element.count = PlantManagementCount.offlinePlants;

          break;
        case 'Alerts':
          element.count = PlantManagementCount.alerts;

          break;
        case 'No Alerts':
          element.count = PlantManagementCount.noAlerts;
          break;

        default:
          break;
      }
    });
  }

  getSolarData() {
    let getSolarPlantMangementData = new Subject<boolean>();
    if (this.solarContinuationToken !== null) {
      this.solarDataSubscription = this.si_service
        .getSolarPlantMangementData(
          this.authService.loggedUser.installer_id,
          this.pageSize,
          this.solarContinuationToken,
        )
        .subscribe({
          next: (value: PlantManagementSolar) => {
            const mouseoverEvent = new MouseEvent('mouseover');
            this.solarContinuationToken = '';
            this.solarContinuationToken = value.data.continuationToken;
            this.plantResponseList = value.data.plantResponseList;
            this.apiResponse = this.plantResponseList as (
              | solarplantResponseList
              | non_solarplantResponseList
            )[];
            value?.data?.plantResponseList?.forEach(
              (val: solarplantResponseList) => {
                val = this.si_service.checkSolarRecord(val);
                val.isSelected = false;
                val.lastUpdated = this.si_service.utilService.unixToDate(
                  val.lastUpdated ? +val.lastUpdated : 0,
                );
              },
            );
            let tempResponseList = value.data.plantResponseList.filter(
              (value: solarplantResponseList) => {
                return value.dataloggerSN != 'iFC0FE796271E';
              },
            );

            // To be reverted back

            //Normal Code

            this.solarPlantData.set(
              this.curSolarPage,
              value.data.plantResponseList,
            );
            this.copysolarPlantData.set(
              this.curSolarPage,
              value.data.plantResponseList,
            );

            // //Abnormal Code
            // this.solarPlantData.set(this.curSolarPage, tempResponseList);
            // this.copysolarPlantData.set(this.curSolarPage, tempResponseList);
            // //Abnormal Code end

            this.solarPlantManagementData =
              this.solarPlantData.get(this.curSolarPage) ?? [];
            this.apiResponse = this.solarPlantManagementData as (
              | solarplantResponseList
              | non_solarplantResponseList
            )[];

            this.copysolarPlantManagementData = [
              ...this.solarPlantManagementData,
            ];
            this.plantHeader = this.solarPlantListHeaders;
            this.plantHeader = this.plantHeader.filter(
              (q: string) => q != 'isSelected',
            );
            if (this.selectedFilters.length < 18) {
              this.confirmHandler(mouseoverEvent);
            }
            getSolarPlantMangementData.next(true);
          },
          complete: () => {
            getSolarPlantMangementData.complete();
          },
        });
    } else {
      this.toastr.info('No more records');
      this.prevSolarPage = this.solarPlantData.size - 2;
      this.displayNext = true;
    }
    return getSolarPlantMangementData;
  }
  getNonSolarData() {
    let getNonSolarPlantMangementData = new Subject<boolean>();
    if (this.non_solarContinuationToken !== null) {
      this.nonSolarDataSubscription = this.si_service
        .getNonSolarPlantMangementData(
          this.authService.loggedUser.installer_id,
          this.pageSize,
          this.non_solarContinuationToken,
        )
        .subscribe({
          next: (value: PlantManagementNonSolar) => {
            this.non_solarContinuationToken = '';
            this.non_solarContinuationToken = value?.data?.continuationToken;
            const mouseoverEvent = new MouseEvent('mouseover');
            /**
             * Show data
             */
            value?.data?.plantResponseList?.forEach(
              (val: non_solarplantResponseList) => {
                val = this.si_service.checkNonSolarRecord(val);

                val.lastUpdated = this.si_service.utilService.unixToDate(
                  val.lastUpdated ? +val.lastUpdated : 0,
                );

                val.isSelected = false;
              },
            );

            this.non_solarPlantData.set(
              this.curNonSolarPage,
              value.data.plantResponseList,
            );
            this.copynon_solarPlantData.set(
              this.curNonSolarPage,
              value.data.plantResponseList,
            );
            this.non_solarPlantManagementData =
              this.non_solarPlantData.get(this.curNonSolarPage) ?? [];
            this.copynon_solarPlantManagementData = [
              ...this.non_solarPlantManagementData,
            ];
            if (this.selectedFilters.length < 15) {
              this.confirmHandler(mouseoverEvent);
            }
            getNonSolarPlantMangementData.next(true);
          },
          complete: () => getNonSolarPlantMangementData.complete(),
        });
    } else {
      this.toastr.info('No more records');
      this.displayNext = true;
      this.prevNonsolarPage = this.non_solarPlantData.size - 2;
    }
    return getNonSolarPlantMangementData;
  }
  getBMSData() {}

  reloadData() {
    switch (this.curTab) {
      case 'solar':
        if (this.curSolarPage == 0) {
          return;
        }
        this.solarPlantManagementData =
          this.solarPlantData.get(this.curSolarPage) ?? [];
        this.apiResponse = this.solarPlantManagementData as (
          | solarplantResponseList
          | non_solarplantResponseList
        )[];
        this.columns = this.getReportHeaders(this.solarPlantListHeaders);
        break;
      case 'non_solar':
        if (this.curNonSolarPage == 0) {
          return;
        }
        this.non_solarPlantManagementData =
          this.non_solarPlantData.get(this.curNonSolarPage) ?? [];
        this.apiResponse = this.non_solarPlantManagementData as (
          | solarplantResponseList
          | non_solarplantResponseList
        )[];
        this.columns = this.getReportHeaders(this.nonSolarPlantListHeaders);
        break;
      default:
        break;
    }
  }

  getSolarPlantManagementCount() {
    let solarPlantManagementCount = new Subject<boolean>();
    this.solarPlantManagementCountSubscription = this.si_service
      .getPlantManagementCount(this.authService.loggedUser.installer_id, '1')
      .subscribe({
        next: (value: PlantManagementCount) => {
          this.solarPlantManagementCount = value.data;
          this.updatePlantManagementCount(
            this.plantInfo,
            this.solarPlantManagementCount,
          );
          solarPlantManagementCount.next(true);
        },
        error: (e: Error) => {},
        complete: () => solarPlantManagementCount.complete(),
      });
    return solarPlantManagementCount;
  }
  getnonSolarPlantManagementCount() {
    let nonSolarPlantManagementCount = new Subject<boolean>();
    this.nonSolarPlantManagementCountSubscription = this.si_service
      .getPlantManagementCount(this.authService.loggedUser.installer_id, '2')
      .subscribe({
        next: (value: PlantManagementCount) => {
          this.nonSolarPlantManagementCount = value.data;
          nonSolarPlantManagementCount.next(true);
        },
        error: (e: Error) => {},
        complete: () => nonSolarPlantManagementCount.complete(),
      });
    return nonSolarPlantManagementCount;
  }

  ngOnDestroy(): void {
    this.solarDataSubscription.unsubscribe();
    this.nonSolarDataSubscription.unsubscribe();
    this.solarPlantManagementCountSubscription.unsubscribe();
    this.nonSolarPlantManagementCountSubscription.unsubscribe();
    this.baseUserService.sessionService.stopBnTimer();
  }

  loadNewTab(plantId: string) {
    this.storageService.set(SELECTED_PLANT_ID, plantId);
    let url = `/customer/dashboard?plantId=${plantId}`;
    window.open(url, '_blank');
  }

  

  stringSorter(sortOrder: number, a: string, b: string, key?: string) {
    let unit1 = +a.split(' ')[0];
    let unit2 = +b.split(' ')[0];
    switch (sortOrder) {
      case 1:
        return unit1 - unit2;

      case 2:
        return unit2 - unit1;
    }
    return 0;
  }

  staticValsorter(sortOrder: number, a: string, b: string) {
    const qualityOrder: plainObject = {
      Excellent: 3,
      'Very Good': 2,
      Good: 1,
      Online: 1,
      Offline: 2,
      Charging: 1,
      Discharging: 2,
    };

    switch (sortOrder) {
      case 1:
        return qualityOrder[a] - qualityOrder[b];

      case 2:
        return qualityOrder[b] - qualityOrder[a];
    }
    return 0;
  }

  modelNameSorter(sortOrder: number, a: string, b: string) {
    let unit1 = a.split(' ')[0];
    let unit2 = b.split(' ')[0];
    switch (sortOrder) {
      case 1:
        return unit1.localeCompare(unit2);

      case 2:
        return unit2.localeCompare(unit1);
    }
    return 0;
  }
  treesplantedSorter(sortOrder: number, a: string, b: string) {
    let unit1 = +JSON.stringify(a).split('.')[0];
    let unit2 = +JSON.stringify(b).split('.')[0];
    switch (sortOrder) {
      case 1:
        return unit1 - unit2;

      case 2:
        return unit2 - unit1;
    }
    return 0;
  }
  numericSorter(sortOrder: number, a: string | number, b: string | number) {
    let unit1 = +a;
    let unit2 = +b;
    switch (sortOrder) {
      case 1:
        return unit1 - unit2;

      case 2:
        return unit2 - unit1;
    }
    return 0;
  }

  getReportHeaders(data: Array<string>) {
    let reportHeaders: Array<{
      header: string;
      field: string;
      sequence: number;
    }> = [{ header: 'Plant Name', field: 'plantName', sequence: 0 }];
    for (const [index, item] of data.entries()) {
      reportHeaders.push({
        header: this.headerKeyMap[item],
        field: item,
        sequence: index + 1,
      });
    }
    return reportHeaders;
  }

  unitsCalculator(val: string) {
    let val1 = val.split(' ');
    switch (val1[1]) {
      case 'MWh':
        return +val1[0] * 1000;
      case 'kWh':
        return +val1[0];
    }
    return 0;
  }

 

  inverterRatingsorter(sortOrder: number, a: string, b: string) {
   
    const qualityOrder: plainObject = {
      kVA_and_V: 1,
      kW_and_Phase: 2,
    };
    let newVal1 = 2;
    let newVal2 = 2;

    let val1 = a.split('/');
    let val2 = b.split('/');
    switch (true) {
      case val1[0].endsWith('VA') &&
        val1[1].endsWith('V') &&
        val2[0].endsWith('VA') &&
        val2[1].endsWith('V'):
        return this.compareSameUnits(val1, val2);
      // break;
      case val1[0].endsWith('KW') &&
        val1[1].endsWith('Phase') &&
        val2[0].endsWith('KW') &&
        val2[1].endsWith('Phase'):
        return this.compareSameUnits(val1, val2);
      // break;

      case val1[0].endsWith('VA') &&
        val1[1].endsWith('V') &&
        val2[0].endsWith('KW') &&
        val2[1].endsWith('Phase'):
        newVal1 = qualityOrder['kVA_and_V'];
        newVal2 = qualityOrder['kW_and_Phase'];
        break;
      case val1[0].endsWith('KW') &&
        val1[1].endsWith('Phase') &&
        val2[0].endsWith('VA') &&
        val2[1].endsWith('V'):
        newVal2 = qualityOrder['kVA_and_V'];
        newVal1 = qualityOrder['kW_and_Phase'];
        break;
    }
    switch (sortOrder) {
      case 1:
        return newVal1 - newVal2;

      case 2:
        return newVal2 - newVal1;
    }
    return 0;
  }

  compareSameUnits(a: Array<string>, b: Array<string>) {
    let value = 0;
    let kvUnit1 = this.getVal(a[0]);
    let kvUnit2 = this.getVal(b[0]);
    let vUnit1 = this.getVal(a[1]);
    let vUnit2 = this.getVal(b[1]);
    let newVal1 = 0;
    let newVal2 = 0;
    switch (true) {
      case kvUnit1 <= kvUnit2 && vUnit1 <= vUnit2:
        newVal1 = 1;
        newVal2 = 2;
        break;
      case kvUnit2 <= kvUnit1 && vUnit2 <= vUnit1:
        newVal1 = 2;
        newVal2 = 1;
        break;
    }
    switch (this.sortOrder) {
      case 1:
        value = newVal1 - newVal2;
        break;
      case 2:
        value = newVal2 - newVal1;
        break;

      default:
        break;
    }
    return value;
  }

  getVal(str: string) {
    return parseFloat(str.match(/\d+(\.\d+)?/)![0]);
  }

  sortData(key: string) {
    let val = 0;

    if (this.curTab == 'solar') {
      let copy: Array<solarplantResponseList> = JSON.parse(
        JSON.stringify(this.solarPlantData.get(this.curSolarPage)),
      );
      let data = copy.slice();

      if (key == this.selectedColumn) {
        switch (this.sortOrder) {
          case 1:
            data = copy.sort((a: any, b: any) => {
              switch (key) {
                case 'solarPVInstalled':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'solarPower':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'coalNotburned':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'co2Saved':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'todayGeneration':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'lifetimeGeneration':
                  val = this.numericSorter(
                    this.sortOrder,
                    this.unitsCalculator(a[key]),
                    this.unitsCalculator(b[key]),
                  );
                  break;
                case 'alert':
                  val = a[key].localeCompare(b[key]);
                  break;
                case 'customerName':
                  val = a[key].localeCompare(b[key]);
                  break;
                case 'plantHealth':
                  val = a[key].localeCompare(b[key]);
                  break;
                case 'dataloggerSignalStrength':
                  // val = a[key].localeCompare(b[key]);
                  val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'status':
                  // val = a[key].localeCompare(b[key]);
                  val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'modelName':
                  val = this.modelNameSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'dataloggerSN':
                  val = a[key].localeCompare(b[key]);
                  break;
                case 'treesPlanted':
                  val = this.treesplantedSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'roi':
                  val = this.numericSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'savings':
                  val = this.numericSorter(
                    this.sortOrder,
                    a[key].split(' ')[1],
                    b[key].split(' ')[1],
                  );
                  break;
                case 'plantCreationDate':
                  val = this.dateSorter(a[key], b[key], this.sortOrder);
                  break;

                case 'plantOnlineRatio':
                  val = this.numericSorter(
                    this.sortOrder,
                    a['onlineDays'],
                    b['onlineDays'],
                  );
                  break;

                default:
                  val = a[key] - b[key];
              }

              return val;
            });

            this.solarPlantManagementData = data;
            this.sortOrder++;
            break;
          case 2:
            data = copy.sort((a: any, b: any) => {
              switch (key) {
                case 'solarPVInstalled':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'solarPower':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'coalNotburned':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'co2Saved':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'todayGeneration':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'lifetimeGeneration':
                  val = this.numericSorter(
                    this.sortOrder,
                    this.unitsCalculator(a[key]),
                    this.unitsCalculator(b[key]),
                  );
                  break;
                case 'alert':
                  val = b[key].localeCompare(a[key]);
                  break;
                case 'customerName':
                  val = b[key].localeCompare(a[key]);
                  break;
                case 'plantHealth':
                  val = b[key].localeCompare(a[key]);
                  break;
                case 'dataloggerSignalStrength':
                  // val = b[key].localeCompare(a[key]);
                  val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'status':
                  // val = a[key].localeCompare(b[key]);
                  val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'modelName':
                  val = this.modelNameSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'dataloggerSN':
                  val = b[key].localeCompare(a[key]);
                  break;
                case 'treesPlanted':
                  val = this.treesplantedSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'roi':
                  val = this.numericSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'savings':
                  val = this.numericSorter(
                    this.sortOrder,
                    a[key].split(' ')[1],
                    b[key].split(' ')[1],
                  );
                  break;
                case 'inverterRating':
                  val = this.inverterRatingsorter(
                    this.sortOrder,
                    a[key],
                    b[key],
                  );
                  break;
                case 'plantCreationDate':
                  val = this.dateSorter(a[key], b[key], this.sortOrder);
                  break;
                case 'plantOnlineRatio':
                  val = this.numericSorter(
                    this.sortOrder,
                    a['onlineDays'],
                    b['onlineDays'],
                  );
                  break;

                default:
                  val = b[key] - a[key];
              }

              return val;
            });
            this.sortOrder++;
            this.solarPlantManagementData = data;

            break;
          case 3:
            this.solarPlantManagementData =
              this.solarPlantData.get(this.curSolarPage) ?? [];
            this.selectedColumn = '';
            this.sortOrder = 1;
            break;
        }
      } else {
        if (key !== this.selectedColumn) {
          this.selectedColumn = key;
          this.sortOrder = 1;
          switch (this.sortOrder) {
            case 1:
              data = copy.sort((a: any, b: any) => {
                switch (key) {
                  case 'solarPVInstalled':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'solarPower':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'coalNotburned':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'co2Saved':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'todayGeneration':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'lifetimeGeneration':
                    val = this.numericSorter(
                      this.sortOrder,
                      this.unitsCalculator(a[key]),
                      this.unitsCalculator(b[key]),
                    );
                    break;
                  case 'alert':
                    val = a[key].localeCompare(b[key]);
                    break;
                  case 'customerName':
                    val = a[key].localeCompare(b[key]);
                    break;
                  case 'plantHealth':
                    val = a[key].localeCompare(b[key]);
                    break;
                  case 'dataloggerSignalStrength':
                    // val = a[key].localeCompare(b[key]);
                    val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'modelName':
                    val = this.modelNameSorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'status':
                    // val = a[key].localeCompare(b[key]);
                    val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'dataloggerSN':
                    val = a[key].localeCompare(b[key]);
                    break;
                  case 'treesPlanted':
                    val = this.treesplantedSorter(
                      this.sortOrder,
                      a[key],
                      b[key],
                    );
                    break;
                  case 'roi':
                    val = this.numericSorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'savings':
                    val = this.numericSorter(
                      this.sortOrder,
                      a[key].split(' ')[1],
                      b[key].split(' ')[1],
                    );
                    break;
                  case 'inverterRating':
                    val = this.inverterRatingsorter(
                      this.sortOrder,
                      a[key],
                      b[key],
                    );
                    break;
                  case 'plantCreationDate':
                    val = this.dateSorter(a[key], b[key], this.sortOrder);
                    break;
                  case 'plantOnlineRatio':
                    val = this.numericSorter(
                      this.sortOrder,
                      a['onlineDays'],
                      b['onlineDays'],
                    );
                    break;

                  default:
                    val = b[key] - a[key];
                }

                return val;
              });

              this.sortOrder++;
              this.solarPlantManagementData = data;

              break;
            case 2:
              data = copy.sort((a: any, b: any) => {
                switch (key) {
                  case 'solarPVInstalled':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'solarPower':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'coalNotburned':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'co2Saved':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'todayGeneration':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'lifetimeGeneration':
                    val = this.numericSorter(
                      this.sortOrder,
                      this.unitsCalculator(a[key]),
                      this.unitsCalculator(b[key]),
                    );
                    break;
                  case 'alert':
                    val = b[key].localeCompare(a[key]);
                    break;
                  case 'customerName':
                    val = b[key].localeCompare(a[key]);
                    break;
                  case 'plantHealth':
                    val = b[key].localeCompare(a[key]);
                    break;
                  case 'modelName':
                    val = this.modelNameSorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'dataloggerSignalStrength':
                    val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'status':
                    val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'dataloggerSN':
                    val = b[key].localeCompare(a[key]);
                    break;
                  case 'treesPlanted':
                    val = this.treesplantedSorter(
                      this.sortOrder,
                      a[key],
                      b[key],
                    );
                    break;
                  case 'roi':
                    val = this.numericSorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'savings':
                    val = this.numericSorter(
                      this.sortOrder,
                      a[key].split(' ')[1],
                      b[key].split(' ')[1],
                    );
                    break;
                  case 'inverterRating':
                    val = this.inverterRatingsorter(
                      this.sortOrder,
                      a[key],
                      b[key],
                    );
                    break;
                  case 'plantCreationDate':
                    val = this.dateSorter(a[key], b[key], this.sortOrder);
                    break;
                  case 'plantOnlineRatio':
                    val = this.numericSorter(
                      this.sortOrder,
                      a['onlineDays'],
                      b['onlineDays'],
                    );
                    break;

                  default:
                    val = b[key] - a[key];
                }

                return val;
              });

              this.sortOrder++;
              this.solarPlantManagementData = data;

              break;
            case 3:
              this.solarPlantManagementData =
                this.solarPlantData.get(this.curSolarPage) ?? [];
              this.sortOrder = 1;
              this.selectedColumn = '';
              break;
          }
          return;
        }
      }
    } else {
      val = 0;
      let nonsolar_copy: Array<non_solarplantResponseList> = JSON.parse(
        JSON.stringify(this.non_solarPlantData.get(this.curNonSolarPage)),
      );

      let data = nonsolar_copy.slice();
      if (key == this.selectedColumn) {
        switch (this.sortOrder) {
          case 1:
            data = nonsolar_copy.sort((a: any, b: any) => {
              switch (key) {
                case 'solarPVInstalled':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'solarPower':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'coalNotburned':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'co2Saved':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'todayGeneration':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'lifetimeGeneration':
                  val = this.numericSorter(
                    this.sortOrder,
                    this.unitsCalculator(a[key]),
                    this.unitsCalculator(b[key]),
                  );
                  break;
                case 'alert':
                  val = a[key].localeCompare(b[key]);
                  break;
                case 'customerName':
                  val = a[key].localeCompare(b[key]);
                  break;
                case 'plantHealth':
                  val = a[key].localeCompare(b[key]);
                  break;
                case 'dataloggerSignalStrength':
                  // val = a[key].localeCompare(b[key]);
                  val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'status':
                  // val = a[key].localeCompare(b[key]);
                  val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'batteryState':
                  // val = a[key].localeCompare(b[key]);
                  val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'modelName':
                  val = this.modelNameSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'dataloggerSN':
                  val = a[key].localeCompare(b[key]);
                  break;
                case 'treesPlanted':
                  val = this.treesplantedSorter(this.sortOrder, a[key], b[key]);
                  // let unit1 = +JSON.stringify(a[key]).split('.')[0];
                  // let unit2 = +JSON.stringify(b[key]).split('.')[0];
                  // val = unit1 - unit2;
                  break;
                case 'roi':
                  val = this.numericSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'currentBackupCapacity':
                  val = this.numericSorter(
                    this.sortOrder,
                    a[key].split(' ')[0],
                    b[key].split(' ')[0],
                  );
                  break;
                case 'gridVoltage':
                  val = this.numericSorter(
                    this.sortOrder,
                    a[key].split(' ')[0],
                    b[key].split(' ')[0],
                  );
                  break;
                case 'noofPowerCut':
                  val = this.numericSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'powerCutDuration':
                  val = this.powercutsorter(a[key], b[key], this.sortOrder);
                  break;
                case 'totalBackupCapacity':
                  val = this.numericSorter(
                    this.sortOrder,
                    a[key].split(' ')[0],
                    b[key].split(' ')[0],
                  );
                  break;
                case 'batteryVoltage':
                  val = this.numericSorter(
                    this.sortOrder,
                    a[key].split(' ')[0],
                    b[key].split(' ')[0],
                  );
                  break;
                case 'plantCreationDate':
                  val = this.dateSorter(a[key], b[key], this.sortOrder);
                  break;
                case 'plantOnlineRatio':
                  val = this.numericSorter(
                    this.sortOrder,
                    a['onlineDays'],
                    b['onlineDays'],
                  );
                  break;

                default:
                  val = a[key] - b[key];
              }

              return val;
            });

            this.non_solarPlantManagementData = data;
            this.sortOrder++;
            break;
          case 2:
            data = nonsolar_copy.sort((a: any, b: any) => {
              switch (key) {
                case 'solarPVInstalled':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'solarPower':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'coalNotburned':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'co2Saved':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'todayGeneration':
                  val = this.stringSorter(this.sortOrder, a[key], b[key]);

                  break;
                case 'lifetimeGeneration':
                  val = this.numericSorter(
                    this.sortOrder,
                    this.unitsCalculator(a[key]),
                    this.unitsCalculator(b[key]),
                  );
                  break;
                case 'alert':
                  val = b[key].localeCompare(a[key]);
                  break;
                case 'customerName':
                  val = b[key].localeCompare(a[key]);
                  break;
                case 'plantHealth':
                  val = b[key].localeCompare(a[key]);
                  break;
                case 'dataloggerSignalStrength':
                  // val = b[key].localeCompare(a[key]);
                  val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'status':
                  // val = a[key].localeCompare(b[key]);
                  val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'batteryState':
                  // val = a[key].localeCompare(b[key]);
                  val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'modelName':
                  val = this.modelNameSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'dataloggerSN':
                  val = b[key].localeCompare(a[key]);
                  break;
                case 'treesPlanted':
                  val = this.treesplantedSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'roi':
                  val = this.numericSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'currentBackupCapacity':
                  val = this.numericSorter(
                    this.sortOrder,
                    a[key].split(' ')[0],
                    b[key].split(' ')[0],
                  );
                  break;
                case 'gridVoltage':
                  val = this.numericSorter(
                    this.sortOrder,
                    a[key].split(' ')[0],
                    b[key].split(' ')[0],
                  );
                  break;
                case 'totalBackupCapacity':
                  val = this.numericSorter(
                    this.sortOrder,
                    a[key].split(' ')[0],
                    b[key].split(' ')[0],
                  );
                  break;
                case 'batteryVoltage':
                  val = this.numericSorter(
                    this.sortOrder,
                    a[key].split(' ')[0],
                    b[key].split(' ')[0],
                  );
                  break;
                case 'powerCutDuration':
                  val = this.powercutsorter(a[key], b[key], this.sortOrder);
                  break;
                case 'inverterRating':
                  val = this.inverterRatingsorter(
                    this.sortOrder,
                    a[key],
                    b[key],
                  );
                  break;
                case 'noofPowerCut':
                  val = this.numericSorter(this.sortOrder, a[key], b[key]);
                  break;
                case 'plantCreationDate':
                  val = this.dateSorter(a[key], b[key], this.sortOrder);
                  break;
                case 'plantOnlineRatio':
                  val = this.numericSorter(
                    this.sortOrder,
                    a['onlineDays'],
                    b['onlineDays'],
                  );
                  break;

                default:
                  val = b[key] - a[key];
              }

              return val;
            });
            this.sortOrder++;
            this.non_solarPlantManagementData = data;

            break;
          case 3:
            this.non_solarPlantManagementData =
              this.non_solarPlantData.get(this.curNonSolarPage) ?? [];
            this.selectedColumn = '';
            this.sortOrder = 1;
            break;
        }
      } else {
        if (key !== this.selectedColumn) {
          this.selectedColumn = key;
          this.sortOrder = 1;
          switch (this.sortOrder) {
            case 1:
              data = nonsolar_copy.sort((a: any, b: any) => {
                switch (key) {
                  case 'solarPVInstalled':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'solarPower':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'coalNotburned':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'co2Saved':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'todayGeneration':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'lifetimeGeneration':
                    val = this.numericSorter(
                      this.sortOrder,
                      this.unitsCalculator(a[key]),
                      this.unitsCalculator(b[key]),
                    );
                    break;
                  case 'alert':
                    val = a[key].localeCompare(b[key]);
                    break;
                  case 'customerName':
                    val = a[key].localeCompare(b[key]);
                    break;
                  case 'plantHealth':
                    val = a[key].localeCompare(b[key]);
                    break;
                  case 'dataloggerSignalStrength':
                    // val = a[key].localeCompare(b[key]);
                    val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'modelName':
                    val = this.modelNameSorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'status':
                    // val = a[key].localeCompare(b[key]);
                    val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'batteryState':
                    // val = a[key].localeCompare(b[key]);
                    val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'dataloggerSN':
                    val = a[key].localeCompare(b[key]);
                    break;
                  case 'treesPlanted':
                    val = this.treesplantedSorter(
                      this.sortOrder,
                      a[key],
                      b[key],
                    );
                    break;
                  case 'roi':
                    val = this.numericSorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'currentBackupCapacity':
                    val = this.numericSorter(
                      this.sortOrder,
                      a[key].split(' ')[0],
                      b[key].split(' ')[0],
                    );
                    break;
                  case 'totalBackupCapacity':
                    val = this.numericSorter(
                      this.sortOrder,
                      a[key].split(' ')[0],
                      b[key].split(' ')[0],
                    );
                    break;
                  case 'gridVoltage':
                    val = this.numericSorter(
                      this.sortOrder,
                      a[key].split(' ')[0],
                      b[key].split(' ')[0],
                    );
                    break;
                  case 'batteryVoltage':
                    val = this.numericSorter(
                      this.sortOrder,
                      a[key].split(' ')[0],
                      b[key].split(' ')[0],
                    );
                    break;
                  case 'noofPowerCut':
                    val = this.numericSorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'powerCutDuration':
                    val = this.powercutsorter(a[key], b[key], this.sortOrder);
                    break;
                  case 'inverterRating':
                    val = this.inverterRatingsorter(
                      this.sortOrder,
                      a[key],
                      b[key],
                    );
                    break;
                  case 'plantCreationDate':
                    val = this.dateSorter(a[key], b[key], this.sortOrder);
                    break;
                  case 'plantOnlineRatio':
                    val = this.numericSorter(
                      this.sortOrder,
                      a['onlineDays'],
                      b['onlineDays'],
                    );
                    break;

                  default:
                    val = b[key] - a[key];
                }

                return val;
              });

              this.sortOrder++;
              this.non_solarPlantManagementData = data;

              break;
            case 2:
              data = nonsolar_copy.sort((a: any, b: any) => {
                switch (key) {
                  case 'solarPVInstalled':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'solarPower':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'coalNotburned':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'co2Saved':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'todayGeneration':
                    val = this.stringSorter(this.sortOrder, a[key], b[key]);

                    break;
                  case 'lifetimeGeneration':
                    val = this.numericSorter(
                      this.sortOrder,
                      this.unitsCalculator(a[key]),
                      this.unitsCalculator(b[key]),
                    );
                    break;
                  case 'alert':
                    val = b[key].localeCompare(a[key]);
                    break;
                  case 'customerName':
                    val = b[key].localeCompare(a[key]);
                    break;
                  case 'plantHealth':
                    val = b[key].localeCompare(a[key]);
                    break;
                  case 'modelName':
                    val = this.modelNameSorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'dataloggerSignalStrength':
                    val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                    // val = b[key].localeCompare(a[key]);
                    break;
                  case 'status':
                    // val = a[key].localeCompare(b[key]);
                    val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'batteryState':
                    // val = a[key].localeCompare(b[key]);
                    val = this.staticValsorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'dataloggerSN':
                    val = b[key].localeCompare(a[key]);
                    break;
                  case 'treesPlanted':
                    val = this.treesplantedSorter(
                      this.sortOrder,
                      a[key],
                      b[key],
                    );
                    break;
                  case 'roi':
                    val = this.numericSorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'currentBackupCapacity':
                    val = this.numericSorter(
                      this.sortOrder,
                      a[key].split(' ')[0],
                      b[key].split(' ')[0],
                    );
                    break;
                  case 'totalBackupCapacity':
                    val = this.numericSorter(
                      this.sortOrder,
                      a[key].split(' ')[0],
                      b[key].split(' ')[0],
                    );
                    break;
                  case 'batteryVoltage':
                    val = this.numericSorter(
                      this.sortOrder,
                      a[key].split(' ')[0],
                      b[key].split(' ')[0],
                    );
                    break;
                  case 'powerCutDuration':
                    val = this.powercutsorter(a[key], b[key], this.sortOrder);
                    break;
                  case 'inverterRating':
                    val = this.inverterRatingsorter(
                      this.sortOrder,
                      a[key],
                      b[key],
                    );
                    break;
                  case 'gridVoltage':
                    val = this.inverterRatingsorter(
                      this.sortOrder,
                      a[key],
                      b[key],
                    );
                    break;
                  case 'noofPowerCut':
                    val = this.numericSorter(this.sortOrder, a[key], b[key]);
                    break;
                  case 'plantCreationDate':
                    val = this.dateSorter(a[key], b[key], this.sortOrder);
                    break;
                  case 'plantOnlineRatio':
                    val = this.numericSorter(
                      this.sortOrder,
                      a['onlineDays'],
                      b['onlineDays'],
                    );
                    break;

                  default:
                    val = b[key] - a[key];
                }

                return val;
              });

              this.sortOrder++;
              this.non_solarPlantManagementData = data;

              break;
            case 3:
              this.non_solarPlantManagementData =
                this.non_solarPlantData.get(this.curNonSolarPage) ?? [];
              this.selectedColumn = '';
              this.sortOrder = 1;
              break;
          }
          return;
        }
      }
    }
  }

  dateSorter(a: string, b: string, sortOrder: number) {
    let d1 = a.split(' ')[0].split('-');
    let d2 = b.split(' ')[0].split('-');

    let date1 = new Date(d1[1] + '-' + d1[0] + '-' + d1[2]);
    let date2 = new Date(d2[1] + '-' + d2[0] + '-' + d2[2]);
    let val: number = 0;
    switch (sortOrder) {
      case 1:
        val = +date1 - +date2;

        break;
      case 2:
        val = +date2 - +date1;
        break;

      default:
        break;
    }
    return val;
  }

  powercutsorter(a: string, b: string, sortOrder: number) {
    let hrUnit1 = a.split(' ')[0];
    let hrUnit2 = b.split(' ')[0];
    let minUnit1 = a.split(' ')[2];
    let minUnit2 = b.split(' ')[2];
    let newVal1 = 0;
    let newVal2 = 0;

    switch (true) {
      case hrUnit1 >= hrUnit2 && minUnit1 >= minUnit2:
        newVal1 = 1;
        newVal2 = 2;

        break;
      case hrUnit2 >= hrUnit2 && minUnit2 >= minUnit2:
        newVal1 = 2;
        newVal2 = 1;

        break;
    }

    switch (sortOrder) {
      case 1:
        return newVal1 - newVal2;

      case 2:
        return newVal2 - newVal1;
    }
    return 0;
  }
}
