import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { BaseComponent } from '../../../../base/components/base.component';
import { ProductService } from '../../../../shared/service/product.service';
import { CustomerService } from '../../../services/customer.service';
import {
  Assignee,
  AssigneeResult,
} from '../../../../shared/models/assignee-response';
import { USER_KEY } from '../../../../shared/service/storage.service';
import { Subject } from 'rxjs/internal/Subject';
import { CreateTicket } from '../../../../shared/models/create-ticket';
import { ViewTicket } from '../../../../shared/models/view-ticket';
import {
  UpdateTicket,
  UpdateTicketDetail,
} from '../../../../shared/models/update-ticket';

const URL = 'path_to_api';

@Component({
  selector: 'app-ticket-dialog',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule, ReactiveFormsModule],
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss'],
})
export class TicketDialogComponent extends BaseComponent {
  userresponse!: Assignee;

  plantName = localStorage.getItem('plantname');
  isOpen = false;
  faClose = faClose;
  ticketForm: FormGroup;
  value = false;

  imageUrls: any[] = [];
  categories = ['Solar'];
  assignees: Array<String> = [];
  priorities = ['P1', 'P2', 'P3', 'P4'];
  ticketStatus = ['Open', 'In Progress', 'Closed'];
  prodService = inject(ProductService);
  customerService = inject(CustomerService);

  form: any;
  newForm!: any;
  image!: string | ArrayBuffer | null;

  @ViewChild('fileInput') fileInput!: ElementRef;
  update!: boolean;

  images: any | null = [];

  updatedForm!: UpdateTicketDetail;
  documentId!: string;
  ticketId!: string;
  largeImage: string | null = null;
  closeImg: any;
  showValidationMessage = false;

  imageDelete(index: number): void {
    this.images.splice(index, 1);
    let imageURL = this.imageUrls[index];

    this.imageUrls.splice(index, 1);
    this.customerService.deleteImage(imageURL).subscribe({
      next: (response) => {},
      error: (error) => {
        console.error('Error uploading image:', error);
      },
    });
    this.largeImage = null;
  }

  closeLargeImage() {
    this.largeImage = null;
  }

  showLargeImg(index: number, event: MouseEvent): void {
    event.stopPropagation();
    this.largeImage = this.images[index].preview;
  }

  constructor(private fb: FormBuilder) {
    super();
    this.ticketForm = this.fb.group({
      assignee: ['', [Validators.required]],
      category: ['', [Validators.required]],
      priority: ['', [Validators.required]],
      ticketStatus: ['', [Validators.required]],
      subject: [
        '',
        [
          Validators.required,
          Validators.minLength(25),
          Validators.maxLength(100),
        ],
      ],
      description: ['', [Validators.required]],
      comment: ['', [Validators.required]],
    });
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.largeImage = null;
    this.images.splice(0, this.imageArrayLength);
    this.ticketForm.reset();
  }
  ngOnInit() {
    this.resolveAllSubscriptions([this.getAssigneeList()]);
  }

  resetFileInput(): void {
    if (this.fileInput?.nativeElement) {
      this.image = '';
      this.fileInput.nativeElement.value = '';
    }
  }
  get imageArrayLength(): number {
    return this.images.length;
  }

  getAssigneeList() {
    let loaderSubject = new Subject<boolean>();
    let siUserId = this.storageService.getObject(USER_KEY).id;

    this.customerService.getAssigneeDetails(siUserId).subscribe({
      next: (response: Assignee) => {
        loaderSubject.next(true);
        this.userresponse = response;
        for (let x of response) {
          this.assignees.push(x.name);
        }
      },
      error: (error) => {},
      complete: () => {
        loaderSubject.complete();
      },
    });
    return loaderSubject;
  }
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input && input.files && input.files.length > 0) {
      const selectedFile = input.files[0];

      this.processFile(selectedFile);
      this.uploadImage(selectedFile);

      input.value = '';
    }
  }

  processFile(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.images.push({
        file,
        preview: reader.result as string,
      });
    };
    reader.readAsDataURL(file);
  }

  uploadImage(selectedFile: File | null): void {
    if (selectedFile) {
      this.customerService.uploadImage(selectedFile).subscribe({
        next: (response) => {
          let image = response;
          if (image) {
            this.imageUrls.push(response);
          }
        },
        error: (error) => {
          console.error('Error uploading image:', error);
        },
      });
    }
  }

  submitFormData() {
    let siUserId = this.storageService.getObject(USER_KEY).id;
    let pId = localStorage.getItem('pId');
    let form = this.ticketForm.value;
    let value = this.ticketForm.get('assignee')?.value;
    let assigneeedetails = this.userresponse.find(
      (user) => user.name === value,
    );

    if (this.update) {
      this.updatedForm = {
        ...form,
        imageUrl: this.imageUrls,
        siUserId: siUserId,
        id: this.documentId,
        ticketId: this.ticketId,
        phone: assigneeedetails?.phone,
        email: assigneeedetails?.email,
        plantId: pId,
      };
      console.log(this.updatedForm);
      this.customerService.updateTicket(this.updatedForm).subscribe({
        next: (response: UpdateTicket) => {
          this.toastr.info(response.message);
          this.ticketForm.reset();
          this.toggle();
        },
        error: (error) => {},
      });
    } else {
      this.newForm = {
        ...form,
        imageUrl: this.imageUrls,
        siUserId: siUserId,
        plantId: pId,
        phone: assigneeedetails?.phone ?? '',
        email: assigneeedetails?.email ?? '',
      };

      this.customerService.createTicket(this.newForm).subscribe({
        next: (response: CreateTicket) => {
          this.toastr.info(response.message);
          this.ticketForm.reset();
          this.toggle();
        },
        error: (error) => {},
        complete: () => {},
      });
    }
    this.resetFileInput();
  }

  getFileNameFromUrl(url: string): string {
    const decodedUrl = decodeURIComponent(url);
    return decodedUrl.substring(decodedUrl.lastIndexOf('/') + 1);
  }

  triggerFileInput(): void {
    const fileInput = document.getElementById('file') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  openFormEditable(id: string, update: boolean, documentid: string) {
    this.ticketId = id;
    this.update = update;
    this.documentId = documentid;
    this.customerService.viewTicket(id).subscribe({
      next: (formdata: ViewTicket) => {
        let values = formdata.data;
        this.ticketForm.patchValue({
          assignee: values.assignee,
          category: values.category,
          priority: values.priority,
          ticketStatus: values.ticketStatus,
          subject: values.subject,
          description: values.description,
          comment: values.comment,
        });

        let imagess = values.imageUrl ?? [];
        this.imageUrls = values.imageUrl ?? [];
        this.images =
          imagess.length > 0
            ? imagess.map((img) => ({
                file: { name: this.getFileNameFromUrl(img) },
                preview: img,
              }))
            : [];
      },
    });
  }
}
