<div
  [class.open]="isOpen"
  class="side-sheet fixed top-[10rem] bottom-0 h-[72%] w-[55%] p-10 z-30 right-[-100%] bg-[#22282F] rounded-lg my-4 py-3 px-5 gradient-border"
>
  <div class="flex flex-col pr-32 h-full max-h-full max-w-full w-full">
    <div class="w-full h-full flex flex-col overflow-auto">
      <h2 class="text-xl font-semibold mb-4">{{ plantName }}</h2>

      <div class="grid grid-cols-4 w-full text-white -ml-[1%] h-fit">
        <div class="flex-col ml-2">
          <div>
            <label for="category" class="block text-sm mb-2">Category</label>
          </div>
          <div class="mt-[3%]">
            <span class="text-center">{{ plantInformation?.category }} </span>
          </div>
        </div>
        <div class="flex-col">
          <div>
            <label for="category" class="block text-sm mb-2">Assignee</label>
          </div>
          <div class="mt-[3%]">
            <span>{{ plantInformation?.assignee }}</span>
          </div>
        </div>
        <div class="flex-col">
          <div>
            <label for="category" class="block text-sm mb-2">Priority</label>
          </div>
          <div class="mt-[3%]">
            <span>{{ plantInformation?.priority }}</span>
          </div>
        </div>
        <div class="flex-col">
          <div>
            <label for="category" class="block text-sm mb-2"
              >Ticket Status</label
            >
          </div>
          <div class="mt-[3%]">
            <span>{{ plantInformation?.ticketStatus }}</span>
          </div>
        </div>
      </div>

      <div class="flex-col mt-[3%] flex h-fit">
        <div><label class="block text-sm mb-2">Subject</label></div>
        <div class="mt-[2%]">
          <p>{{ plantInformation?.subject }}</p>
        </div>
      </div>

      <div class="flex-col mt-[2%] h-fit">
        <div>
          <label class="block text-sm mb-2"> Description </label>
        </div>
        <div class="mt-[2%]">
          <p>{{ plantInformation?.description }}</p>
        </div>
      </div>

      <div class="flex-col mt-[2%] h-fit">
        <div><label class="block text-sm mb-2">Comment</label></div>
        <div class="mt-[2%]">
          <p>{{ plantInformation?.comment }}</p>
        </div>
      </div>
      <div class="flex-grow"></div>
      <div class="flex justify-start space-x-3 cancelbutton">
        <button
          type="button"
          (click)="toggle()"
          class="box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-5 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isOpen" class="overlay" (click)="toggle()"></div>
