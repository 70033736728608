<div class="h-full w-full flex flex-grow">
  @if (statData !== null) {
    <div
      class="bg-white dark:bg-btTh p-1 rounded-2xl w-full flex justify-center items-center mx-2"
    >
      @if (!isDeviceOffline) {
        @if (!noxAlert) {
          @if (
            statData?.plantType === plantType.SOLAR &&
            statData?.inverterType === inverterType.GTI ||
            statData?.inverterType === inverterType.ThinkPower

          ) {
            <div>
              <div
                class="grid grid-cols-5 justify-center items-center pt-8 pb-3 px-3"
              >
                <div
                  class="col-span-1 rounded-full flex justify-center items-center energy-bg-size m-auto border-[2px] border-solid"
                  [ngClass]="
                    this.statData?.stats?.grid_state?.toString() == '1'
                      ? 'border-light-red-variant-1'
                      : 'border-light-green-variant-1'
                  "
                >
                  <img
                    src="../../../../../assets/svg/solar-icon.svg"
                    class="energy-icon-size"
                    alt="preview"
                  />
                </div>
                <div class="w-full">
                  <div class="w-full bg-white rounded-sm overflow-hidden">
                    <div class="h-1 w-full">
                      <div
                        class="progress"
                        [ngClass]="{
                          'animate-left-to-right': animationFlow.solarToCenter
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-span-1 rounded-full flex justify-center items-center m-auto"
                >
                  <img
                    src="../../../../../assets/svg/preview-center.svg"
                    class="w-10 h-10"
                    alt="preview"
                  />
                </div>
                <div class="w-full">
                  <div class="w-full bg-white rounded-sm overflow-hidden">
                    <div class="h-1 w-full">
                      <div
                        class="progress"
                        [ngClass]="{
                          'animate-left-to-right': animationFlow.centerToGrid,
                          'animate-right-to-left': animationFlow.gridToCenter
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-span-1 rounded-full flex justify-center items-center energy-bg-size m-auto border-[2px] border-solid"
                  [ngClass]="
                    this.statData?.stats?.grid_state?.toString() == '1'
                      ? 'border-light-red-variant-1'
                      : 'border-light-green-variant-1'
                  "
                >
                  <img
                    src="../../../../../assets/svg/grid-icon.svg"
                    class="energy-icon-size"
                    alt="preview"
                  />
                </div>
              </div>
              <div class="flex justify-between px-3">
                <div class="flex flex-col flex-wrap items-center">
                  <span class="text-xs dark:text-white opacity-50">{{
                    "solar_power" | translate
                  }}</span>
                  <span class="text-[10px] py-[2px] m-0 leading-none text-white"
                    >{{ statData?.stats?.solar_power ?? 0 }} kW</span
                  >
                </div>
                <span class="text-xs dark:text-white opacity-50">{{
                  "grid_voltage" | translate
                }}</span>
              </div>
              <div
                class="flex flex-wrap my-4 mt-4 px-3"
                [ngClass]="
                  +statData!.stats.phase_a_voltage != 0 &&
                  +statData!.stats.phase_b_voltage != 0 &&
                  +statData!.stats.phase_c_voltage != 0
                    ? 'justify-between'
                    : 'justify-center'
                "
              >
                <div
                  class="col-span-1"
                  *ngIf="+statData!.stats.phase_a_voltage != 0"
                >
                  <div>
                    <span
                      class="font-roboto text-[11px] font-normal tracking-normal text-left opacity-50 dark:text-white"
                    >
                      {{ "a_phase" | translate }}
                    </span>
                  </div>
                  <div>
                    <span
                      class="font-roboto text-xs font-normal tracking-normal text-left dark:text-white"
                    >
                      {{ statData!.stats.phase_a_voltage + " V" || "NA" }}
                    </span>
                  </div>
                </div>

                <div
                  class="col-span-1"
                  *ngIf="+statData!.stats.phase_b_voltage != 0"
                >
                  <div>
                    <span
                      class="font-roboto text-[11px] font-normal tracking-normal text-left opacity-50 dark:text-white"
                    >
                      {{ "b_phase" | translate }}
                    </span>
                  </div>
                  <div>
                    <span
                      class="font-roboto text-xs font-normal tracking-normal text-left dark:text-white"
                    >
                      {{ statData!.stats!.phase_b_voltage + " V" || "NA" }}
                    </span>
                  </div>
                </div>
                <div
                  class="col-span-1"
                  *ngIf="+statData!.stats.phase_c_voltage != 0"
                >
                  <div>
                    <span
                      class="font-roboto text-[11px] font-normal tracking-normal text-left opacity-50 dark:text-white"
                    >
                      {{ "c_phase" | translate }}
                    </span>
                  </div>
                  <div>
                    <span
                      class="font-roboto text-xs font-normal tracking-normal text-left dark:text-white"
                    >
                      {{ statData!.stats.phase_c_voltage + " V" || "NA" }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex flex-col justify-center items-center">
                @if (statData?.stats?.grid_state == "1") {
                  <span class="text-white label-xs text-center m-auto mb-5">
                    Solar generation paused due to power cut, will resume when
                    power's back.
                  </span>
                }
                @if (
                  statData?.inverterRating && statData?.inverterRating! <= "25"
                ) {
                  <div
                    class="flex justify-center items-center rounded-xl bg-dark-accent-secondary px-4 py-3 text-white cursor-default gap-x-1"
                    (click)="sideSheet.toggle()"
                  >
                    <span class="text-xs p-0">{{
                      "string_info" | translate
                    }}</span>
                    <img
                      src="../../../../../assets/svg/arrow_forward.svg"
                      alt="arrow"
                    />
                  </div>

                  <div class="text-white cursor-default">
                    <app-string-info [statsData]="statData"></app-string-info>
                  </div>
                }
              </div>
            </div>
          }
          <!-- Preview SolarVerter -->
          @if (
            this.statData?.plantType === plantType.SOLAR &&
            statData?.inverterType !== inverterType.GTI &&
            statData?.inverterType !== inverterType.ThinkPower

          ) {
            <div>
              <div class="flex justify-center items-center">
                <div class="justify-center items-center px-2 text-white mr-5">
                  <div class="flex flex-col flex-wrap items-center">
                    <span class="text-[10px] p-0 opacity-50 m-0 leading-none">{{
                      "solar_power" | translate
                    }}</span>
                    <span class="text-[10px] py-[2px] m-0 leading-none"
                      >{{ statData?.stats?.solar_power ?? 0 }} kW</span
                    >
                  </div>
                </div>
              </div>
              <div class="flex flex-row flex-grow my-1 justify-center">
                <div
                  class="grid grid-cols-5 row-span-5 justify-center items-center px-3 grid-auto"
                >
                  <div
                    class="col-span-5 rounded-full flex justify-center items-center energy-bg-size m-auto border-[2px] border-solid"
                    [ngClass]="
                      this.statData?.stats?.solar_state?.toString() == '0'
                        ? 'border-light-red-variant-1'
                        : 'border-light-green-variant-1'
                    "
                  >
                    <img
                      src="../../../../../assets/svg/solar-icon.svg"
                      class="energy-icon-size"
                      alt="preview"
                    />
                  </div>
                  <div class="col-span-5 flex justify-center m-0 h-full">
                    <div class="h-full bg-white rounded-sm overflow-hidden">
                      <div class="h-full w-1">
                        <div
                          class="progress-vertical"
                          [ngClass]="{
                            'animate-top-to-bottom': animationFlow.solarToCenter
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-span-1 rounded-full flex justify-center items-center energy-bg-size m-auto border-[2px] border-solid"
                    [ngClass]="
                      this.statData?.stats?.grid_state?.toString() == '0'
                        ? 'border-light-red-variant-1'
                        : 'border-light-green-variant-1'
                    "
                  >
                    <img
                      src="../../../../../assets/svg/grid-icon.svg"
                      class="energy-icon-size"
                      alt="preview"
                    />
                  </div>
                  <div class="w-full">
                    <div class="w-full bg-white rounded-sm overflow-hidden">
                      <div class="h-1 w-full">
                        <div
                          class="progress"
                          [ngClass]="{
                            'animate-left-to-right': animationFlow.gridToCenter,
                            'animate-right-to-left': animationFlow.centerToGrid
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-span-1 rounded-full flex justify-center items-center w-10 h-10 m-auto"
                  >
                    <img
                      src="../../../../../assets/svg/preview-center.svg"
                      class="w-10 h-10"
                      alt="preview"
                    />
                  </div>
                  <div class="w-full">
                    <div class="w-full bg-white rounded-sm overflow-hidden">
                      <div class="h-1 w-full">
                        <div
                          class="progress"
                          [ngClass]="{
                            'animate-left-to-right': animationFlow.centerToHome
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-span-1 rounded-full flex justify-center items-center energy-bg-size m-auto border-[2px] border-solid"
                    [ngClass]="
                      animationFlow.centerToHome
                        ? 'border-light-green-variant-1'
                        : 'border-light-gray-variant-1'
                    "
                  >
                    <img
                      src="../../../../../assets/svg/home-icon.svg"
                      class="energy-icon-size"
                      alt="preview"
                    />
                  </div>

                  <div
                    class="col-span-2 flex flex-col justify-start items-start"
                  >
                    <div class="flex flex-col flex-wrap items-center">
                      <span
                        class="text-[10px] p-0 opacity-50 mt-1 leading-none text-black dark:text-white"
                        >{{ "grid_voltage" | translate }}</span
                      >
                      <span class="text-[10px] pt-1 leading-none text-white"
                        >{{
                          this.statData?.stats?.input_voltage ?? "0"
                        }}
                        V</span
                      >
                    </div>
                  </div>
                  <div class="col-span-1 flex justify-center m-0 h-full">
                    <div class="h-full bg-white rounded-sm overflow-hidden">
                      <div class="h-full w-1">
                        <div
                          class="progress-vertical"
                          [ngClass]="{
                            'animate-top-to-bottom':
                              animationFlow.centerToBattery,
                            'animate-bottom-to-top':
                              animationFlow.batteryToCenter
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-2 flex justify-end items-start">
                    <div class="flex flex-col flex-wrap items-center">
                      <span
                        class="text-[10px] p-0 opacity-50 mr-2 leading-none text-black dark:text-white"
                        >{{ "home" | translate }}</span
                      >
                      <span
                        class="text-[10px] pt-1 mr-2 leading-none text-white"
                      >
                        {{ this.statData?.stats?.consumptionValue ?? "0" }}
                        kW</span
                      >
                    </div>
                  </div>
                  <div class="col-span-2"></div>
                  <div
                    class="col-span-1 rounded-full flex justify-center items-center energy-bg-size m-auto border-[2px] border-solid"
                    [ngClass]="
                      animationFlow.centerToBattery ||
                      animationFlow.batteryToCenter
                        ? 'border-light-green-variant-1'
                        : 'border-light-gray-variant-1'
                    "
                  >
                    <div class="relative w-fit">
                      <div class="energy-icon-progress">
                        <div
                          class="w-full h-full absolute top-0 left-0 flex justify-end items-end border-[0.5px] border-solid border-white rounded-b-sm"
                        >
                          <div
                            class="w-full bg-[#0051ba] rounded-b-sm"
                            [ngStyle]="{
                              height: calculateBatteryProgress(statData?.stats)
                            }"
                          ></div>
                        </div>
                        <div class="battery-top-left"></div>
                        <div class="battery-top-right"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-2"></div>
                </div>
                <div class="flex justify-center items-center">
                  <span class="vertical-text text-[#94989E] label-2xs m-0"
                    >Load Priority: {{ statData?.solarSetting }}</span
                  >
                </div>
              </div>

              <div class="flex justify-center items-center">
                <div class="justify-center items-center px-2 text-white mr-5">
                  <div class="flex flex-col flex-wrap items-center">
                    <span class="text-[10px] p-0 opacity-50 m-0 leading-none">{{
                      this.statData?.stats?.batteryState?.toString() != "0"
                        ? "Charging"
                        : getState(this.statData?.stats)
                    }}</span>
                    @if (
                      this.statData?.stats?.batteryState?.toString() != "0"
                    ) {
                      <span class="text-[10px] p-0 mt-1 leading-none text-white"
                        >(
                        {{
                          this.statData?.stats?.time_remaining_for_charging
                            | noxDate
                        }}
                        to charge)</span
                      >
                    } @else {
                      @if (
                        getNumber(statData?.stats?.discharging_current) > 1 ||
                        getNumber(statData?.stats?.discharge) > 1
                      ) {
                        <span
                          class="text-[10px] p-0 mt-1 leading-none text-white"
                          >(
                          {{
                            this.statData?.stats?.available_backup
                              ? (this.statData?.stats?.available_backup
                                | noxDate)
                              : (this.statData?.stats
                                  ?.time_remaining_for_discharging | noxDate)
                          }}
                          remaining)</span
                        >
                      } @else {
                        <span
                          class="text-[10px] p-0 mt-1 leading-none text-white"
                          >(
                          {{
                            this.statData?.stats?.time_remaining_for_charging
                              | noxDate
                          }}
                          to charge)</span
                        >
                      }
                    }
                  </div>
                </div>
              </div>
            </div>
          }

          <!-- Non solar SolarVerter -->
          @if (this.statData?.plantType === plantType.NON_SOLAR) {
            <div>
              <div
                class="grid grid-cols-5 row-span-5 justify-center items-center px-3 mt-16 grid-auto"
              >
                <div
                  class="col-span-1 rounded-full flex justify-center items-center energy-bg-size m-auto border-[2px] border-solid"
                  [ngClass]="
                    this.statData?.stats?.grid_state?.toString() == '0'
                      ? 'border-light-red-variant-1'
                      : 'border-light-green-variant-1'
                  "
                >
                  <img
                    src="../../../../../assets/svg/grid-icon.svg"
                    class="energy-icon-size"
                    alt="preview"
                  />
                </div>
                <div class="w-full">
                  <div class="w-full bg-white rounded-sm overflow-hidden">
                    <div class="h-1 w-full">
                      <div
                        class="progress"
                        [ngClass]="{
                          'animate-left-to-right': animationFlow.gridToCenter,
                          'animate-right-to-left': animationFlow.centerToGrid
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-span-1 rounded-full flex justify-center items-center w-10 h-10 m-auto"
                >
                  <img
                    src="../../../../../assets/svg/preview-center.svg"
                    class="w-10 h-10"
                    alt="preview"
                  />
                </div>
                <div class="w-full">
                  <div class="w-full bg-white rounded-sm overflow-hidden">
                    <div class="h-1 w-full">
                      <div
                        class="progress"
                        [ngClass]="{
                          'animate-left-to-right': animationFlow.centerToHome
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-span-1 rounded-full flex justify-center items-center energy-bg-size m-auto border-[2px] border-solid"
                  [ngClass]="
                    animationFlow.centerToHome
                      ? 'border-light-green-variant-1'
                      : 'border-light-gray-variant-1'
                  "
                >
                  <img
                    src="../../../../../assets/svg/home-icon.svg"
                    class="energy-icon-size"
                    alt="preview"
                  />
                </div>

                <div class="col-span-2 flex flex-col justify-start items-start">
                  <span
                    class="text-[10px] p-0 opacity-50 m-0 leading-none text-black dark:text-white"
                    >{{ "grid_voltage" | translate }}</span
                  >
                  <span
                    class="text-[10px] p-0 ms-2 mt-1 leading-none text-white"
                    >{{ this.statData?.stats?.input_voltage ?? "0" }} V</span
                  >
                </div>
                <div class="col-span-1 flex justify-center m-0 h-full">
                  <div class="h-full bg-white rounded-sm overflow-hidden">
                    <div class="h-full w-1">
                      <div
                        class="progress-vertical"
                        [ngClass]="{
                          'animate-top-to-bottom':
                            animationFlow.centerToBattery,
                          'animate-bottom-to-top': animationFlow.batteryToCenter
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="col-span-2 flex flex-col justify-end items-end">
                  <div class="flex flex-col justify-center items-center">
                    <span
                      class="text-[10px] p-0 opacity-50 m-0 mx-3 leading-none text-black dark:text-white"
                      >Home</span
                    >
                    @if (statData?.stats?.mappingProtocol != "BITWISE") {
                      <span class="text-[10px] p-0 mt-1 leading-none text-white"
                        >{{
                          this.statData?.stats?.consumptionValue ?? "0"
                        }}
                        kW</span
                      >
                    }
                  </div>
                </div>
                <div class="col-span-2"></div>
                <div
                  class="col-span-1 rounded-full flex justify-center items-center energy-bg-size m-auto border-[2px] border-solid"
                  [ngClass]="
                    animationFlow.centerToBattery ||
                    animationFlow.batteryToCenter
                      ? 'border-light-green-variant-1'
                      : 'border-light-gray-variant-1'
                  "
                >
                  <div class="relative w-fit">
                    <div class="energy-icon-progress">
                      <div
                        class="w-full h-full absolute top-0 left-0 flex justify-end items-end border-[0.5px] border-solid border-white rounded-b-sm"
                      >
                        <div
                          class="w-full bg-[#0051ba] rounded-b-sm"
                          [ngStyle]="{
                            height: calculateBatteryProgress(statData?.stats)
                          }"
                        ></div>
                      </div>
                      <div class="battery-top-left"></div>
                      <div class="battery-top-right"></div>
                    </div>
                  </div>
                </div>
                <div class="col-span-2"></div>
              </div>

              <div class="flex flex-col justify-center items-center">
                <div class="justify-center items-center px-2 text-white">
                  <span class="text-[10px] p-0 opacity-50 m-0 leading-none">{{
                    this.statData?.stats?.batteryState?.toString() !== "0"
                      ? "Charging"
                      : getState(this.statData?.stats)
                  }}</span>
                </div>
                @if (this.statData?.stats?.batteryState?.toString() !== "0") {
                  <span class="text-[10px] p-0 mt-1 leading-none text-white"
                    >(
                    {{
                      this.statData?.stats?.time_remaining_for_charging
                        | noxDate
                    }}
                    to charge)</span
                  >
                } @else {
                  @if (
                    getNumber(statData?.stats?.discharging_current) > 1 ||
                    getNumber(statData?.stats?.discharge) > 1
                  ) {
                    <span class="text-[10px] p-0 mt-1 leading-none text-white"
                      >(
                      {{
                        this.statData?.stats?.time_remaining_for_discharging
                          | noxDate
                      }}
                      remaining)</span
                    >
                  } @else {
                    <span class="text-[10px] p-0 mt-1 leading-none text-white"
                      >(
                      {{
                        this.statData?.stats?.time_remaining_for_charging
                          | noxDate
                      }}
                      to charge)</span
                    >
                  }
                }
              </div>
            </div>
          }
        } @else {
          <!-- Alert UI -->
          <div class="flex flex-col justify-around items-center h-full">
            <div class="flex flex-col">
              <span class="text-white w-full text-center"
                >Inverter Detected
              </span>
              @if (noxAlert === alertType.OFFLINE) {
                <span class="text-white w-full text-center">Short circuit</span>
              } @else if (noxAlert === alertType.INVERTER_OVERHEATED) {
                <span class="text-white w-full text-center"
                  >High Temperature</span
                >
              } @else if (
                noxAlert === alertType.SHORT_CIRCUIT_AND_INVERTER_OVERHEATED
              ) {
                <span class="text-white w-full text-center"
                  >Short Circuit and High Temperature</span
                >
              }
            </div>
            <img
              src="../../../../../assets/svg/short-circuit-alert.svg"
              class="w-20 h-20"
              alt="preview"
            />
            <span class="text-white text-xs text-center lead"
              >Fault has been detected in your inverter. We recommend you to
              restart the inverter.</span
            >
            <div
              class="flex flex-col text-white text-[10px] text-center leading-tight"
            >
              <span> In case of Any Emergency </span>
              <span
                >Contact our
                <span class="text-dark-accent-secondary cursor-default">
                  Customer Support</span
                ></span
              >
            </div>
          </div>
        }
      } @else {
        <div class="flex flex-col justify-center items-center h-full">
          <div
            class="w-[85px] h-[85px] bg-[#455160] rounded-full flex justify-center items-center"
          >
            <img
              src="../../../../../assets/svg/offline_plant.svg"
              class="w-16 h-16 object-fil ms-1 mb-1"
              alt="preview"
            />
          </div>
          <span class="text-md text-white mt-6">Your device is offline</span>

          <div
            class="flex flex-row justify-center items-center rounded-xl bg-dark-accent-secondary px-4 py-[10px] text-white mt-10 cursor-default"
            (click)="openDialog()"
          >
            <span class="text-xs p-0">{{ "click_here" | translate }}</span>
            <fa-icon [icon]="faChevronRight" class="ml-2 text-xs"></fa-icon>
          </div>
        </div>
      }
    </div>
  }
</div>
