import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  inject,
  signal,
} from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
  Routes,
} from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../base/components/base.component';
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from '../../service/auth.service';
import { ProductService } from '../../service/product.service';
import {
  INVERTER_TYPE,
  SELECTED_PLANT_ID,
  StorageService,
  USER_KEY,
  USER_PLANT,
} from '../../service/storage.service';
import { PlantItemData, PlantListData } from '../../models/plants';
import { query } from '@angular/animations';
import { PlantDetail } from '../../models/plant';
import { MenuItem } from '../../models/generic';
import { User, UserCategory, UserType } from '../../models/user';
import { SecondaryRoles } from '../../interfaces/secondary-roles';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    MatListModule,
    MatIconModule,
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatTabsModule,
    TranslateModule,
    FontAwesomeModule,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent
  extends BaseComponent
  implements OnDestroy, OnInit
{
  navigateToDashboard(planData: PlantItemData) {
    this.storageService.set(INVERTER_TYPE, planData.inverterType);
    this.router.navigate(['/customer/dashboard'], {
      queryParams: { plantId: planData.id },
    });
  }
  isPlantExist(planData: PlantListData | null | undefined) {
    if (planData) {
      if (
        planData.primaryPlant.length > 0 ||
        planData.secondaryPlant.length > 0
      ) {
        return true;
      }
    }
    return false;
  }
  selectedPlantId?: string;
  selectPlant(plantId: string) {
    this.router.navigate(['/customer/dashboard'], {
      queryParams: { plantId: plantId },
    });
  }
  @Input({ required: true }) collapsed = signal<boolean>(false);
  showTooltip = false;
  faCircle = faCircle;
  faCircleCheck = faCircleCheck;
  isPrimaryPlant = true;
  isCustomer: boolean = true;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  menuItems = signal<MenuItem[]>([]);
  prodService = inject(ProductService);
  plantList?: PlantListData | null;
  selectedPlant?: PlantDetail | null;
  user!: User;
  isGti: boolean = false;
  userType = UserType;
  activatedRoute = inject(ActivatedRoute);
  isActive: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.user = this.storageService.getObject(USER_KEY);
    let url = this.router.url;
    let isPremiumCustomer = this.user.userCategory === UserCategory.PREMIUM ? true : false;
    switch (this.user.user_type) {
      case this.userType.CUSTOMER:
        this.menuItems = signal<MenuItem[]>([
          {
            icon: 'home-dashboard',
            label: this.translateService.instant('overview'),
            route: 'dashboard/customer-dashboard',
            enable: true,
            disableOption: false,
            position: 'Top',
          },
          {
            icon: 'explore-si',
            label: this.translateService.instant('explore'),
            route: 'about-us',
            enable: true,
            position: 'Top',
          },
          {
            icon: 'explore-qa',
            label: this.translateService.instant('help_and_support'),
            route: 'help',
            enable: true,
            position: 'Bottom',
          },
          {
            icon: 'settings-si',
            label: this.translateService.instant('setting'),
            route: 'setting',
            enable: true,
            position: 'Bottom',
          },
        ]);
        break;
      case this.userType.SYSTEM_INTEGRATOR:
        let isViewAsCustomer = url.includes('/customer');
        if (!isViewAsCustomer) {
          this.menuItems = signal<MenuItem[]>([
            {
              icon: 'home-dashboard',
              label: this.translateService.instant('dashboard_management'),
              route: 'dashboard',
              enable: true,
              disableOption: true,
              position: 'Top',
            },
            {
              icon: 'plant-management',
              label: this.translateService.instant('plant_management'),
              route: 'plant-management',
              enable: true,
              position: 'Top',
            },
            {
              icon: 'explore-si',
              label: this.translateService.instant('explore'),
              route: 'about-us',
              enable: true,
              position: 'Top',
            },
            {
              icon: 'explore-qa',
              label: this.translateService.instant('help_and_support'),
              route: 'help',
              enable: true,
              position: 'Bottom',
            },
            {
              icon: 'settings-si',
              label: this.translateService.instant('setting'),
              route: 'setting',
              enable: true,
              position: 'Bottom',
            },
            {
              icon: 'user',
              label: this.translateService.instant('user_management'),
              route: 'user',
              enable: false,
              position: 'Top',
            },
            {
              icon: 'report',
              label: this.translateService.instant('report_management'),
              route: 'report',
              enable: false,
              position: 'Top',
            },
            {
              icon: 'group',
              label: this.translateService.instant('group_management'),
              route: 'group',
              enable: false,
              position: 'Top',
            },
            {
              icon: 'fota',
              label: this.translateService.instant('fota_management'),
              route: 'fota',
              enable: false,
              position: 'Top',
            },
          ]);
        } else {
          this.menuItems = signal<MenuItem[]>(
            this.isGti == true
              ? [
                  {
                    icon: 'home-dashboard',
                    label: this.translateService.instant('overview'),
                    route: 'dashboard/customer-dashboard',
                    enable: true,
                    disableOption: true,
                    position: 'Top',
                  },
                  {
                    icon: 'explore-si',
                    label: this.translateService.instant('explore'),
                    route: 'about-us',
                    enable: true,
                    position: 'Top',
                  },
                  {
                    icon: 'explore-qa',
                    label: this.translateService.instant('help_and_support'),
                    route: 'help',
                    enable: true,
                    position: 'Bottom',
                  },
                  {
                    icon: 'settings-si',
                    label: this.translateService.instant('setting'),
                    route: 'setting',
                    enable: false,
                    position: 'Bottom',
                  },
                  ...(isPremiumCustomer
                    ? [
                        {
                          icon: 'analytic',
                          label: this.translateService.instant('analytics'),
                          route: 'analytics',
                          enable: true,
                          position: 'Top' as 'Top',
                        },
                      ]
                    : []),
                ]
              : 
              [
                  {
                    icon: 'home-dashboard',
                    label: this.translateService.instant('overview'),
                    route: 'dashboard/customer-dashboard',
                    enable: true,
                    disableOption: true,
                    position: 'Top',
                  },
                  {
                    icon: 'explore-si',
                    label: this.translateService.instant('explore'),
                    route: 'about-us',
                    enable: true,
                    position: 'Top',
                  },
                  {
                    icon: 'explore-qa',
                    label: this.translateService.instant('help_and_support'),
                    route: 'help',
                    enable: true,
                    position: 'Bottom',
                  },
                  {
                    icon: 'settings-si',
                    label: this.translateService.instant('setting'),
                    route: 'setting',
                    enable: false,
                    position: 'Bottom',
                  },
                ],
          );
        }
        break;
      case this.userType.SERVICE_ENGINEER:
        this.menuItems = signal<MenuItem[]>([
          {
            icon: 'search-se',
            label: this.translateService.instant('Search'),
            route: 'delete-plant',
            enable: true,
            position: 'Top',
          },
          {
            icon: 'history-se',
            label: this.translateService.instant('History'),
            route: 'history',
            enable: true,
            position: 'Top',
          },
          {
            icon: 'explore-qa',
            label: this.translateService.instant('help_and_support'),
            route: 'help',
            enable: true,
            position: 'Bottom',
          },
          {
            icon: 'settings-si',
            label: this.translateService.instant('setting'),
            route: 'setting',
            enable: true,
            position: 'Bottom',
          },
        ]);
        break;
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentUrl = this.router.url.split('?')[0];
        const expectedUrl = '/customer/dashboard';
        /**
         * Set route class to dashboard when user is on dashboard
         */
        if (currentUrl === expectedUrl) {
          const plantId = this.activatedRoute.snapshot.queryParams['plantId'];
          this.isActive = true;
        } else {
          /*Reset selection and route class when route is switched from dashboard */
          this.isActive = false;
          this.selectedPlant = null;
          this.isPrimaryPlant = true;
        }
      });

    const initialUrl = this.router.url.split('?')[0];
    if (initialUrl === '/customer/dashboard') {
      const initialPlantId =
        this.activatedRoute.snapshot.queryParams['plantId'];
      this.isActive = true;
    } else {
      this.isActive = false;
    }

    this.baseUserService.inverterType.subscribe({
      next: (val: boolean) => {
        
        // if (val == true) { 

          if (this.user.user_type===this.userType.CUSTOMER) {
            this.menuItems = signal<MenuItem[]>([
              {
                icon: 'home-dashboard',
                label: this.translateService.instant('overview'),
                route: 'dashboard/customer-dashboard',
                enable: true,
                disableOption: false,
                position: 'Top',
              },
              {
                icon: 'explore-si',
                label: this.translateService.instant('explore'),
                route: 'about-us',
                enable: true,
                position: 'Top',
              },
              {
                icon: 'explore-qa',
                label: this.translateService.instant('help_and_support'),
                route: 'help',
                enable: true,
                position: 'Bottom',
              },
              {
                icon: 'settings-si',
                label: this.translateService.instant('setting'),
                route: 'setting',
                enable: false,
                position: 'Bottom',
              },
              ...(isPremiumCustomer
                ? [
                    {
                      icon: 'analytic',
                      label: this.translateService.instant('analytics'),
                      route: 'analytics',
                      enable: true,
                      position: 'Top' as 'Top',
                    },
                  ]
                : []),
            ]);
          }
            else{
              this.menuItems = signal<MenuItem[]>([
                {
                  icon: 'home-dashboard',
                  label: this.translateService.instant('overview'),
                  route: 'dashboard/customer-dashboard',
                  enable: true,
                  disableOption: true,
                  position: 'Top',
                },
                {
                  icon: 'explore-si',
                  label: this.translateService.instant('explore'),
                  route: 'about-us',
                  enable: true,
                  position: 'Top',
                },
                {
                  icon: 'explore-qa',
                  label: this.translateService.instant('help_and_support'),
                  route: 'help',
                  enable: true,
                  position: 'Bottom',
                },
                {
                  icon: 'settings-si',
                  label: this.translateService.instant('setting'),
                  route: 'setting',
                  enable: false,
                  position: 'Bottom',
                },
                ...(isPremiumCustomer
                  ? [
                      {
                        icon: 'analytic',
                        label: this.translateService.instant('analytics'),
                        route: 'analytics',
                        enable: true,
                        position: 'Top' as 'Top',
                      },
                    ]
                  : []),
              ]);
            }
      },
    });
    
    this.updatePlantSelection();
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Fetch plant list by user id
   */
  updatePlantSelection() {
    this.prodService.plantList.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (plantList) => {
        this.plantList = plantList;
        if (this.plantList?.primaryPlant.length == 0) {
          this.isPrimaryPlant = false;
        }
      },
    });

    this.prodService.selectedPlant
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (plant) => {
          this.selectedPlant = plant;
        },
      });
  }
  //toggle primary plant
  togglePrimaryPlant() {
    this.isPrimaryPlant = !this.isPrimaryPlant;
  }

  /**
   *
   * @param arg0 Route to customer dashboard on click of overview when si view as customer
   */
  routeToCustomerDashboard(arg0: string) {
  
    let selectedPlantId = this.storageService.get(SELECTED_PLANT_ID);
    this.router.navigate(['/customer/dashboard'], {
      queryParams: { plantId: selectedPlantId },
    });
  }
}
