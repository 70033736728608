import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { ProductService } from '../../service/product.service';
import { StringInfoData } from '../../models/string-info';
import {
  ChartOptions,
  NoxChartDta,
  NoxChartXY,
  NoxStringInfoData,
} from '../../models/nox-chart';
import { DateUtils } from '../../utils/date_utils';
import { ApexAxisChartSeries, NgApexchartsModule } from 'ng-apexcharts';
import { CommonModule } from '@angular/common';
import { StringInfoType } from '../../models/enum';
import { StatsData } from '../../models/stats';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { PlantDetail } from '../../models/plant';
import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-string-info',
  standalone: true,
  imports: [
    NgApexchartsModule,
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
  ],
  templateUrl: './string-info.component.html',
  styleUrl: './string-info.component.scss',
})
export class StringInfoComponent {
  prodService = inject(ProductService);
  stringInfoData?: StringInfoData;
  noxStringInfoData?: NoxStringInfoData;
  noxChartData: NoxChartDta[] = [];
  noxChartXY: NoxChartXY[] = [];
  series: ApexAxisChartSeries = [];
  faClose = faClose;

  chartOptions: Partial<ChartOptions> = {};

  selectedTab: StringInfoType = StringInfoType.VOLTAGE;
  stringInfoType = StringInfoType;

  @Input() statsData?: StatsData;
  selectedPlant?: PlantDetail | null;
  isOpen = false;
  productService = inject(ProductService);

  constructor() {
    this.prodService.selectedPlant.subscribe((plant) => {
      this.selectedPlant = plant;
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getStringTrends();
  }
  /**
   * Select tab option from string info
   * @param tab
   */

  selectTab(tab: StringInfoType) {
    this.selectedTab = tab;
    switch (tab) {
      case StringInfoType.VOLTAGE:
        this.plotVoltageLineChart();

        break;
      case StringInfoType.CURRENT:
        this.plotCurrentChart();

        break;
      case StringInfoType.POWER:
        this.plotPowerLineChart();

        break;
      default:
        break;
    }
  }

  /**
   * Plot power line chart
   */
  plotPowerLineChart() {
    this.noxChartData = [];
    this.noxChartXY = [];

    if (this.stringInfoData?.power) {
      for (let i = 0; i < this.stringInfoData.power.length; i++) {
        this.noxChartXY = [];

        this.stringInfoData.power[i].instantiatepVPower.forEach((item) => {
          if (item.power && item.time) {
            this.noxChartXY.push({
              x: item.time * 1000, // convert to milliseconds
              y: Number(item.power),
            });
          }
        });
        this.noxChartData.push({
          name: 'PV ' + (i + 1),
          noxXY: this.noxChartXY,
        });
      }
      this.noxStringInfoData = {
        noxChartData: this.noxChartData,
        yAxisLabel: 'Power (kW)',
      };

      this.plotLineChart(this.noxStringInfoData);
    }
  }

  /**
   * Plot current line chart
   */
  plotCurrentChart() {
    this.noxChartData = [];
    this.noxChartXY = [];

    if (this.stringInfoData?.current) {
      for (let i = 0; i < this.stringInfoData.current.length; i++) {
        this.noxChartXY = [];
        this.stringInfoData.current[i].instantiatepVCurrent.forEach((item) => {
          this.noxChartXY.push({
            x: item.time * 1000, // convert to milliseconds
            y: Number(item.current),
          });
        });
        this.noxChartData.push({
          name: 'PV ' + (i + 1),
          noxXY: this.noxChartXY,
        });
      }
      this.noxStringInfoData = {
        noxChartData: this.noxChartData,
        yAxisLabel: 'Current (A)',
      };
      this.plotLineChart(this.noxStringInfoData);
    }
  }

  /**
   * Plot voltage line chart
   */

  plotVoltageLineChart() {
    this.noxChartData = [];
    this.noxChartXY = [];

    if (this.stringInfoData?.voltage) {
      for (let i = 0; i < this.stringInfoData.voltage.length; i++) {
        this.noxChartXY = [];
        this.stringInfoData.voltage[i].instantiatepVVoltage.forEach((item) => {
          this.noxChartXY.push({
            x: item.time * 1000, // convert to milliseconds
            y: Number(item.voltage),
          });
        });
        this.noxChartData.push({
          name: 'PV ' + (i + 1),
          noxXY: this.noxChartXY,
        });
      }
      this.noxStringInfoData = {
        noxChartData: this.noxChartData,
        yAxisLabel: 'Voltage (V)',
      };

      this.plotLineChart(this.noxStringInfoData);
    }
  }

  /**
   * Get string trends from API
   */

  getStringTrends() {
    if (this.selectedPlant) {
      this.prodService
        .getStringDetail(
          this.selectedPlant.id,
          DateUtils.getStartDayTimeStampFromDate(new Date()),
        )
        .subscribe((res) => {
          this.stringInfoData = res?.data;

          this.plotVoltageLineChart();
        });
    }
  }

  /**
   * Plot line chart
   * @param data
   */
  plotLineChart(data: NoxStringInfoData) {
    this.series = data.noxChartData.map((item) => {
      return {
        name: item.name,
        data: item.noxXY.map((item) => {
          return {
            x: item.x,
            y: item.y,
          };
        }),
      };
    });
    /**
     * Line chart
     */
    this.chartOptions = {
      chart: {
        type: 'area',
        height: 280,
        foreColor: '#fff',
        toolbar: {
          show: true,
          tools: {
            pan: true,
            //zoom: false,
          },
          autoSelected: 'pan',
          export: {
            csv: {
              dateFormatter(timestamp) {
                return moment(timestamp).format('YYYY-MM-DD HH:mm:ss a');
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: 5,
        },
      },

      legend: {
        show: true,
        position: 'bottom',
        itemMargin: {
          vertical: 8,
        },
      },

      series: this.series,
      stroke: {
        width: 2,
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ['#FFFFFF'],
        },
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 8,
        labels: {
          datetimeUTC: false,
          formatter: function (value, timestamp: number) {
            return DateUtils.formatTimeFromLocalEpoch(timestamp);
          },
          style: {
            colors: '#94989E',
            fontSize: '10',
          },
        },
        title: {
          offsetY: 5,
          text: 'Time (hh:mm)',
          style: {
            color: '#FFFFFF',
            fontSize: '10',
          },
        },
      },
      yaxis: {
        title: {
          text: this.noxStringInfoData?.yAxisLabel ?? '',
          style: {
            color: '#FFFFFF',
            fontSize: '10',
          },
        },
        labels: {
          style: {
            colors: '#94989E',
            fontSize: '10',
          },
        },
      },

      tooltip: {
        y: {
          formatter: (value: number) =>
            `${value} ${data?.yAxisLabel === 'Current (A)' ? 'A' : data?.yAxisLabel === 'Voltage (V)' ? 'V' : 'kW'}`,
        },
        theme: 'dark',
      },
    };
  }
  /**
   * switch side sheet
   */
  toggle(): void {
    this.isOpen = !this.isOpen;
    /**Call API when open string info */
    if (this.isOpen) {
      this.selectedTab = StringInfoType.VOLTAGE;
      this.getStringTrends();
    }
  }
}
