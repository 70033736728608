<div
  class="relative flex flex-grow items-center justify-center h-full m-auto dark-gradient-bg overflow-scroll min-w-[900px] min-h-[620px]"
>
  <div
    class="flex flex-col flex-grow justify-start items-start bg-dark-gray-2 rounded-lg absolute top-0 bottom-0 left-0 right-0 mx-5 my-5 overflow-y-scroll max-auto-scroll"
  >
    <div class="absolute end-0 start-0 py-5 px-10">
      <div class="flex flex-row justify-between items-center flex-grow">
        <div class="space-x-2 cursor-pointer" (click)="goBack()">
          <img
            src="../../../../assets/svg/arrow_back.svg"
            class="rotate-180"
            alt="Back"
          />
          <span class="text-gray-100 gradient-text">{{
            "back" | translate
          }}</span>
        </div>
        @if (isPrimaryUser) {
          <div class="flex flex-row items-center space-x-4 mr-4">
            <div
              class="flex items-center space-x-1 cursor-default"
              (click)="deactivatePlant()"
            >
              <span class="text-gray-100 gradient-text">{{
                "deactivate_plant" | translate
              }}</span>
              <img src="../../../../assets/svg/info-icon.svg" alt="Info" />
            </div>
          </div>
        }
      </div>

      <div class="container px-2">
        <div class="tab_box">
          <button
            class="tab_btn"
            [class.active]="activeTab === 'siteDetails'"
            (click)="activateTab('siteDetails', $event)"
          >
            {{ "site_detail" | translate }}
          </button>
          <button
            class="tab_btn"
            [class.active]="activeTab === 'inverterDetails'"
            (click)="activateTab('inverterDetails', $event)"
          >
            {{ "inverter_details" | translate }}
          </button>
          @if (
            selectedInverterType !== inverterType.GTI.toString() &&
            selectedInverterType !== inverterType.ON_GRID.toString() &&
            selectedInverterType !== inverterType.ThinkPower.toString()
          ) {
            <button
              class="tab_btn"
              [class.active]="activeTab === 'batteryDetails'"
              (click)="activateTab('batteryDetails', $event)"
            >
              {{ "battery_details" | translate }}
            </button>
          }

          @if (
            selectedPlantType !== plantType.NON_SOLAR.toString() &&
            selectedInverterType !== inverterType.NON_SOLAR
          ) {
            <button
              class="tab_btn"
              [class.active]="activeTab === 'solarPvDetails'"
              (click)="activateTab('solarPvDetails', $event)"
            >
              {{ "solar_pv_details" | translate }}
            </button>
          }

          <button
            class="tab_btn"
            [class.active]="activeTab === 'dataLoggerDetails'"
            (click)="activateTab('dataLoggerDetails', $event)"
          >
            {{ "data_logger_details" | translate }}
          </button>
          <button
            class="tab_btn"
            [class.active]="activeTab === 'connectedUser'"
            (click)="activateTab('connectedUser', $event)"
          >
            {{ "connected_user" | translate }}
          </button>
          @if (isPrimaryUser) {
            <button
              class="tab_btn"
              [class.active]="activeTab === 'downloadReport'"
              (click)="activateTab('downloadReport', $event)"
            >
              {{ "download_report" | translate }}
            </button>
          }

          <img
            src="../../../../assets/svg/download_report.svg"
            alt="Download Report"
            class="hidden"
          />

          <div
            id="line"
            #newLine
            [ngStyle]="{ 'width.px': lineWidth, 'left.px': lineLeft }"
          ></div>
        </div>
        <div class="content_box">
          <div
            class="content"
            [ngClass]="{ active: activeTab === 'siteDetails' }"
          >
            <!-- Site Details -->
            <form [formGroup]="plantForm">
              <div class="p-4">
                <div class="tab-content flex flex-col">
                  <div class="grid grid-cols-6 gap-x-5">
                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="name"
                      >
                        {{ "plant_name" | translate }}
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-3"
                      >
                        <input
                          formControlName="plantName"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="text"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>

                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "time_zone" | translate }}
                      </label>
                      <div class="flex flex-col md:flex-row flex-grow">
                        <input
                          formControlName="timeZone"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>
                    <div class="col-span-2"></div>
                    <div class="flex flex-col mb-5 col-span-2 lg:col-span-3">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        Location
                      </label>
                      <div class="flex flex-col md:flex-row flex-grow">
                        <input
                          formControlName="location"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>

                    <div class="flex flex-col mb-5 col-span-1">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "pine_code" | translate }}
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-3"
                      >
                        <input
                          formControlName="pinCode"
                          class="w-36 box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>

                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "currency" | translate }}
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-3"
                      >
                        <input
                          formControlName="currency"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>

                    <!-- 3rd row -->
                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "installation_date" | translate }}
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-3"
                      >
                        <input
                          formControlName="installationDate"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>

                    @if (
                      enableAverageTariff(selectedInverterType) ||
                      selectedPlantType == plantType.NON_SOLAR
                    ) {
                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "average_tariff" | translate }}
                        </label>
                        <div class="flex flex-col md:flex-row flex-grow">
                          <input
                            formControlName="averageTariffRate"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      </div>
                    }

                    <div class="flex flex-col mb-5 col-span-2">
                      @if (enableGridTariff(selectedInverterType)) {
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "grid_feed_tariff" | translate }}
                        </label>
                        <div class="flex flex-col md:flex-row flex-grow">
                          <input
                            formControlName="gridFeedTariff"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      }
                    </div>

                    <!-- forth row -->
                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "installer_electrician_no" | translate }}
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-3"
                      >
                        <input
                          formControlName="electricianNumber"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>

                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "third_party_id" | translate }}
                      </label>
                      <div class="flex flex-col md:flex-row flex-grow">
                        <input
                          formControlName="thirdPartyId"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>
                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "system_integrator_id" | translate }}
                      </label>
                      <div class="flex flex-col md:flex-row flex-grow">
                        <input
                          formControlName="systemIntegratorId"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>

                    <!-- Fifth row -->
                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "Total Investment" | translate }}
                      </label>
                      <div class="flex flex-col md:flex-row md:space-x-3">
                        <input
                          formControlName="totalInvestment"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>
                    @if (selectedInverterType !== inverterType.GTI && selectedInverterType !== inverterType.ThinkPower) {
                      <div class="col-span-4"></div>
                    }
                    <!-- seventh row image -->

                    <div class="flex flex-col mb-5 col-span-1">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "plant_image" | translate }}
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-2"
                      >
                        <div
                          class="w-[100px] h-[100px] border border-solid border-gray-400 rounded-2xl bg-dark-gray-2 text-white gradient-header flex justify-center"
                        >
                          <img
                            [src]="
                              plantForm.controls['plantPhoto'].value
                                ? plantForm.controls['plantPhoto'].value
                                : '../../../../assets/svg/gallery.svg'
                            "
                            alt="Gallery"
                            [ngClass]="{
                              'w-[100px] h-[100px] rounded-2xl':
                                plantForm.controls['plantPhoto'].value,
                              'm-8': !plantForm.controls['plantPhoto'].value
                            }"
                            (click)="
                              plantForm.controls['plantPhoto'].value
                                ? displayImage(
                                    plantForm.controls['plantPhoto'].value
                                  )
                                : null
                            "
                          />
                        </div>
                        <div
                          class="hidden w-1/4 border border-solid border-gray-400 rounded-2xl py-[8px] px-3 bg-dark-gray-2 text-white gradient-header flex justify-center items-center text-xs"
                        >
                          {{ "upload" | translate }}
                          <img
                            src="../../../../assets/svg/upload-fota.svg"
                            class="mx-1"
                            alt="Upload"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-span-3"></div>

                    <div class="hidden flex flex-col mb-5 col-span-2">
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-2"
                      >
                        <div
                          class="border border-solid border-gray-400 rounded-2xl py-[8px] px-3 text-white gradient-header bg-dark-gray-2 flex justify-center items-center"
                        >
                          <div
                            class="rounded-full bg-blue-500 mr-2 w-5 h-5 flex justify-center items-center"
                          >
                            <fa-icon
                              [icon]="faPlus"
                              class="color-white"
                            ></fa-icon>
                          </div>
                          {{ "add" | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="hidden col-span-6 flex justify-st items-center">
                      <div
                        class="flex justify-center items-center rounded-xl bg-dark-accent-secondary px-4 py-[5px] text-white"
                      >
                        <span class="text-xs p-0">{{
                          "save" | translate
                        }}</span>
                      </div>
                    </div>
                    <!-- Sixth row -->

                    <div class="flex flex-col mb-5 col-span-6">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "types_of_appliance" | translate }}
                      </label>
                      @if (
                        plantForm.controls["typesOfAppliance"].value.length > 0
                      ) {
                        <div
                          class="w-1/2 grid grid-cols-5 rounded-lg bg-dark-accent-secondary py-2 px-2"
                        >
                          <div class="col-span-1">
                            <span class="text-white text-xs">Appliances</span>
                          </div>
                          <div class="grid-cols-1">
                            <span class="text-white text-xs">Power</span>
                          </div>
                          <div class="grid-cols-1">
                            <span class="text-white text-xs">Quantity</span>
                          </div>
                          <div class="grid-cols-1">
                            <span class="text-white text-xs">Priority</span>
                          </div>
                          <div class="hidden grid-cols-1">
                            <span class="text-white text-xs">Action</span>
                          </div>
                        </div>
                        <div
                          class="w-1/2 grid grid-cols-5 rounded-lg py-2 px-2"
                        >
                          @for (
                            item of plantForm.controls["typesOfAppliance"]
                              .value;
                            track $index
                          ) {
                            <div class="grid-cols-1">
                              <span class="text-white text-xs">{{
                                item["appliances"]
                              }}</span>
                            </div>
                            <div class="grid-cols-1">
                              <span class="text-white text-xs">{{
                                item["power"]
                              }}</span>
                            </div>
                            <div class="grid-cols-1">
                              <span class="text-white text-xs">{{
                                item["quantity"]
                              }}</span>
                            </div>
                            <div class="grid-cols-1">
                              <span class="text-white text-xs">{{
                                item["priority"]
                              }}</span>
                            </div>
                            <div
                              class="hidden col-span-1 flex flex-row space-x-4"
                            >
                              <fa-icon
                                [icon]="faPen"
                                class="text-white text-xs"
                              ></fa-icon>
                              <fa-icon
                                [icon]="faTrash"
                                class="text-white text-xs"
                              ></fa-icon>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div
            class="content"
            [ngClass]="{ active: activeTab === 'inverterDetails' }"
          >
            <!-- Inverter Details Content -->
            <form [formGroup]="inverterForm">
              <div class="p-4">
                <div class="tab-content flex flex-col">
                  <div class="grid grid-cols-6 gap-x-5">
                    <!-- First row -->
                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "model" | translate }}
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-3"
                      >
                        <input
                          formControlName="model"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>

                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "serial_number" | translate }}
                      </label>
                      <div class="flex flex-col md:flex-row flex-grow">
                        <input
                          formControlName="serialNo"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>
                    <div class="col-span-2"></div>

                    <!-- Second row -->
                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "rating" | translate }}
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-3"
                      >
                        <input
                          formControlName="rating"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>
                    <!-- third row -->
                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "date_of_purchase" | translate }}
                      </label>
                      <div class="flex flex-col md:flex-row flex-grow">
                        <input
                          formControlName="dateOfPurchase"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>
                    <div class="col-span-2"></div>

                    <div class="flex flex-col mb-5 col-span-1 mt-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "Inverter Image" | translate }}
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-2"
                      >
                        <div
                          class="w-[100px] h-[100px] border border-solid border-gray-400 rounded-2xl bg-dark-gray-2 text-white gradient-header flex justify-center"
                        >
                          <img
                            [src]="
                              inverterForm.controls['inverterPhoto'].value
                                ? inverterForm.controls['inverterPhoto'].value
                                : '../../../../assets/svg/gallery.svg'
                            "
                            alt="Gallery"
                            [ngClass]="{
                              'w-[100px] h-[100px] rounded-2xl':
                                inverterForm.controls['inverterPhoto'].value,
                              'm-8':
                                !inverterForm.controls['inverterPhoto'].value
                            }"
                            (click)="
                              inverterForm.controls['inverterPhoto'].value
                                ? displayImage(
                                    inverterForm.controls['inverterPhoto'].value
                                  )
                                : null
                            "
                          />
                        </div>
                        <div
                          class="hidden w-1/4 border border-solid border-gray-400 rounded-2xl py-[8px] px-3 bg-dark-gray-2 text-white gradient-header flex justify-center items-center text-xs"
                        >
                          {{ "upload" | translate }}
                          <img
                            src="../../../../assets/svg/upload-fota.svg"
                            class="mx-1"
                            alt="Upload"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-col mb-5 col-span-1 mt-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "Purchase Bill Image" | translate }}
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-2"
                      >
                        <div
                          class="w-[100px] h-[100px] border border-solid border-gray-400 rounded-2xl bg-dark-gray-2 text-white gradient-header flex justify-center"
                        >
                          <img
                            [src]="
                              inverterForm.controls['purchaseBillPhoto'].value
                                ? inverterForm.controls['purchaseBillPhoto']
                                    .value
                                : '../../../../assets/svg/gallery.svg'
                            "
                            alt="Gallery"
                            [ngClass]="{
                              'w-[100px] h-[100px] rounded-2xl':
                                inverterForm.controls['purchaseBillPhoto']
                                  .value,
                              'm-8':
                                !inverterForm.controls['purchaseBillPhoto']
                                  .value
                            }"
                            (click)="
                              inverterForm.controls['purchaseBillPhoto'].value
                                ? displayImage(
                                    inverterForm.controls['purchaseBillPhoto']
                                      .value
                                  )
                                : null
                            "
                          />
                        </div>
                        <div
                          class="hidden w-1/4 border border-solid border-gray-400 rounded-2xl py-[8px] px-3 bg-dark-gray-2 text-white gradient-header flex justify-center items-center text-xs"
                        >
                          {{ "upload" | translate }}
                          <img
                            src="../../../../assets/svg/upload-fota.svg"
                            class="mx-1"
                            alt="Upload"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-span-3"></div>
                    <!-- Fourth row -->

                    <div class="hidden col-span-6 flex justify-st items-center">
                      <div
                        class="flex justify-center items-center rounded-xl bg-dark-accent-secondary px-4 py-[5px] text-white"
                      >
                        <span class="text-xs p-0">{{
                          "save" | translate
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          @if (
            selectedInverterType !== inverterType.GTI.toString() &&
            selectedInverterType !== inverterType.ON_GRID.toString() &&
            selectedInverterType !== inverterType.ThinkPower.toString()

          ) {
            <div
              class="content"
              [ngClass]="{ active: activeTab === 'batteryDetails' }"
            >
              <!-- Battery Details Content -->
              <form [formGroup]="batteryForm">
                <div class="p-4">
                  <div class="tab-content flex flex-col">
                    <div class="grid grid-cols-6 gap-x-5">
                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "brand" | translate }}
                        </label>
                        <div
                          class="flex flex-col md:flex-row flex-grow md:space-x-3"
                        >
                          <input
                            formControlName="brand"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      </div>

                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "serial_number" | translate }}
                        </label>
                        <div class="flex flex-col md:flex-row flex-grow">
                          <input
                            formControlName="serialNo"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      </div>
                      <div class="col-span-2"></div>

                      <!-- 2nd row -->
                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "type" | translate }}
                        </label>
                        <div
                          class="flex flex-col md:flex-row flex-grow md:space-x-3"
                        >
                          <input
                            formControlName="batteryType"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      </div>

                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "single_battery_capacity" | translate }}
                        </label>
                        <div class="flex flex-col md:flex-row flex-grow">
                          <input
                            formControlName="totalBackUpCapacity"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      </div>
                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "no_of_batteries" | translate }}
                        </label>
                        <div class="flex flex-col md:flex-row flex-grow">
                          <input
                            formControlName="noOfBatteries"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      </div>

                      <!-- third row -->
                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "total_batteries" | translate }}
                        </label>
                        <div
                          class="flex flex-col md:flex-row flex-grow md:space-x-3"
                        >
                          <input
                            formControlName="totalBackUpCapacity"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      </div>

                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          &nbsp;
                        </label>
                        <div
                          class="flex flex-col md:flex-row flex-grow md:space-x-2"
                        >
                          <div
                            class="w-3/6 border border-solid border-gray-400 rounded-2xl py-[8px] px-2 bg-dark-gray-2 text-white gradient-header flex justify-center"
                          >
                            <span class="text-xs"
                              >- 5 + &nbsp;{{ "series" | translate }}</span
                            >
                          </div>
                          <div
                            class="w-3/6 border border-solid border-gray-400 rounded-2xl py-[8px] px-2 bg-dark-gray-2 text-white gradient-header flex justify-center items-center text-xs"
                          >
                            <span class="text-xs"
                              >- 5 + &nbsp;{{ "parallel" | translate }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "Total Backup Capacity (Ah)" | translate }}
                        </label>
                        <div
                          class="flex flex-col md:flex-row flex-grow md:space-x-3"
                        >
                          <input
                            formControlName="totalBackUpCapacity"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      </div>
                      <!-- fourth row -->
                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "Date of Purchase" | translate }}
                        </label>
                        <div
                          class="flex flex-col md:flex-row flex-grow md:space-x-3"
                        >
                          <input
                            formControlName="dateOfPurchase"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      </div>
                      <div class="flex flex-col col-span-4"></div>
                      <!-- fifth row -->

                      <div class="flex flex-col mb-5 col-span-1 mt-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "battery_image" | translate }}
                        </label>
                        <div
                          class="flex flex-col md:flex-row flex-grow md:space-x-2"
                        >
                          <div
                            class="w-[100px] h-[100px] border border-solid border-gray-400 rounded-2xl bg-dark-gray-2 text-white gradient-header flex justify-center"
                          >
                            <img
                              [src]="
                                batteryForm.controls['batteryPhoto'].value
                                  ? batteryForm.controls['batteryPhoto'].value
                                  : '../../../../assets/svg/gallery.svg'
                              "
                              alt="Gallery"
                              [ngClass]="{
                                'w-[100px] h-[100px] rounded-2xl':
                                  batteryForm.controls['batteryPhoto'].value,
                                'm-8':
                                  !batteryForm.controls['batteryPhoto'].value
                              }"
                              (click)="
                                batteryForm.controls['batteryPhoto'].value
                                  ? displayImage(
                                      batteryForm.controls['batteryPhoto'].value
                                    )
                                  : null
                              "
                            />
                          </div>
                          <div
                            class="hidden w-1/4 border border-solid border-gray-400 rounded-2xl py-[8px] px-3 bg-dark-gray-2 text-white gradient-header flex justify-center items-center text-xs"
                          >
                            {{ "upload" | translate }}
                            <img
                              src="../../../../assets/svg/upload-fota.svg"
                              class="mx-1"
                              alt="Upload"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col mb-5 col-span-1 mt-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "purchase_bill_image" | translate }}
                        </label>
                        <div
                          class="flex flex-col md:flex-row flex-grow md:space-x-2"
                        >
                          <div
                            class="w-[100px] h-[100px] border border-solid border-gray-400 rounded-2xl bg-dark-gray-2 text-white gradient-header flex justify-center"
                          >
                            <img
                              [src]="
                                batteryForm.controls['purchaseBillPhoto'].value
                                  ? batteryForm.controls['purchaseBillPhoto']
                                      .value
                                  : '../../../../assets/svg/gallery.svg'
                              "
                              alt="Gallery"
                              [ngClass]="{
                                'w-[100px] h-[100px] rounded-2xl':
                                  batteryForm.controls['purchaseBillPhoto']
                                    .value,
                                'm-8':
                                  !batteryForm.controls['purchaseBillPhoto']
                                    .value
                              }"
                              (click)="
                                batteryForm.controls['purchaseBillPhoto'].value
                                  ? displayImage(
                                      batteryForm.controls['purchaseBillPhoto']
                                        .value
                                    )
                                  : null
                              "
                            />
                          </div>
                          <div
                            class="hidden w-1/4 border border-solid border-gray-400 rounded-2xl py-[8px] px-3 bg-dark-gray-2 text-white gradient-header flex justify-center items-center text-xs"
                          >
                            {{ "upload" | translate }}
                            <img
                              src="../../../../assets/svg/upload-fota.svg"
                              class="mx-1"
                              alt="Upload"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- Fifth row -->
                      <div
                        class="hidden col-span-6 flex justify-st items-center"
                      >
                        <div
                          class="flex justify-center items-center rounded-xl bg-dark-accent-secondary px-4 py-[5px] text-white"
                        >
                          <span class="text-xs p-0">{{
                            "save" | translate
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          }

          @if (
            selectedPlantType !== plantType.NON_SOLAR.toString() &&
            selectedInverterType !== inverterType.NON_SOLAR
          ) {
            <div
              class="content"
              [ngClass]="{ active: activeTab === 'solarPvDetails' }"
            >
              <!-- Solar PV Details Content -->
              <form [formGroup]="solarForm">
                <div class="p-4">
                  <div class="tab-content flex flex-col">
                    <div class="grid grid-cols-6 gap-x-5">
                      <!-- first row -->
                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "total_installed_capacity" | translate }}
                        </label>
                        <div class="flex flex-row flex-grow md:space-x-3">
                          <input
                            formControlName="totalInstalledCapacity"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      </div>

                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          &nbsp;
                        </label>
                        <div class="flex flex-row flex-grow md:space-x-2">
                          <div
                            class="w-2/6 border border-solid border-gray-400 rounded-2xl py-[8px] px-2 bg-dark-gray-2 text-white gradient-header flex justify-center"
                          >
                            <span class="text-xs"
                              >- 5 + &nbsp;{{ "parallel" | translate }}</span
                            >
                          </div>
                          <div
                            class="w-2/6 border border-solid border-gray-400 rounded-2xl py-[8px] px-2 bg-dark-gray-2 text-white gradient-header flex justify-center items-center text-xs"
                          >
                            <span class="text-xs"
                              >- 5 + &nbsp;{{ "series" | translate }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-span-2"></div>

                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "tilt_angle" | translate }}
                        </label>
                        <div class="flex flex-row flex-grow md:space-x-3">
                          <input
                            formControlName="tiltAngle"
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                          />
                        </div>
                      </div>

                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "Orientation" | translate }}
                        </label>
                        <div class="flex flex-row flex-grow">
                          <input
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-span-2"></div>

                      <!-- 2nd row -->
                      <div class="flex flex-col mb-5 col-span-2">
                        <div class="flex flex-row space-x-4">
                          <label
                            class="block text-white mb-2 opacity-80 text-xs gradient-header"
                            for="email"
                          >
                            {{ "solar_tracking" | translate }}
                          </label>

                          <label class="switch">
                            <input type="checkbox" checked disabled />
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div
                          class="flex flex-row flex-grow md:space-x-3 items-center"
                        >
                          <input
                            id="draft"
                            class="peer/draft"
                            type="radio"
                            name="status"
                            checked
                            disabled
                          />
                          <label for="draft" class="text-white text-xs"
                            >Single Axis</label
                          >

                          <input
                            id="published"
                            class="peer/published"
                            type="radio"
                            name="status"
                            disabled
                          />
                          <label for="published" class="text-white text-xs"
                            >Dual Axis</label
                          >
                        </div>
                      </div>

                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "earthing" | translate }}
                        </label>
                        <div class="flex flex-row flex-grow space-x-2">
                          <div
                            class="dark:bg-none px-4 py-[3px] dark:text-white rounded-xl border-solid border-[0.5px] border-gray-300 flex items-center space-x-2 cursor-pointer"
                            [ngClass]="{
                              'bg-dark-accent-secondary':
                                solarForm.controls['earthing'].value === true
                            }"
                          >
                            <span> {{ "Y" }}</span>
                          </div>
                          <div
                            class="dark:bg-none px-4 py-[3px] dark:text-white rounded-xl border-solid border-[0.5px] border-gray-300 flex items-center space-x-2 cursor-pointer"
                            [ngClass]="{
                              'bg-dark-accent-secondary':
                                solarForm.controls['earthing'].value === false
                            }"
                          >
                            <span> {{ "N" }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "light_arrester" | translate }}
                        </label>
                        <div class="flex flex-row flex-grow space-x-2">
                          <div
                            class="dark:bg-none px-4 py-[3px] dark:text-white rounded-xl border-solid border-[0.5px] border-gray-300 flex items-center space-x-2 cursor-pointer"
                            [ngClass]="{
                              'bg-dark-accent-secondary':
                                solarForm.controls['lighteningArrester']
                                  .value === true
                            }"
                          >
                            <span> {{ "Y" }}</span>
                          </div>
                          <div
                            class="dark:bg-none px-4 py-[3px] dark:text-white rounded-xl border-solid border-[0.5px] border-gray-300 flex items-center space-x-2 cursor-pointer"
                            [ngClass]="{
                              'bg-dark-accent-secondary':
                                solarForm.controls['lighteningArrester']
                                  .value === false
                            }"
                          >
                            <span> {{ "N" }}</span>
                          </div>
                        </div>
                      </div>

                      <!-- 3rd row -->

                      <div class="flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "Installation Place" | translate }}
                        </label>

                        <div
                          class="flex flex-row flex-grow md:space-x-3 items-center"
                        >
                          <input
                            id="draft"
                            class="peer/draft"
                            type="radio"
                            name="status"
                            disabled
                            [checked]="
                              solarForm.controls['installationPlace'].value ===
                              1
                            "
                          />
                          <label for="draft" class="text-white text-xs"
                            >Roof top</label
                          >

                          <input
                            id="published"
                            class="peer/published"
                            type="radio"
                            name="status"
                            disabled
                            [checked]="
                              solarForm.controls['installationPlace'].value ===
                              2
                            "
                          />
                          <label for="published" class="text-white text-xs"
                            >Ground Mounted</label
                          >
                        </div>
                      </div>

                      <div class="hidden flex flex-col mb-5 col-span-2">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "Date of Purchase" | translate }}
                        </label>
                        <div class="flex flex-col md:flex-row flex-grow">
                          <input
                            class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                            id="email"
                            type="email"
                            placeholder="xxxxxxxxxxx"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-span-4"></div>

                      <!-- fifth row -->

                      <div class="flex flex-col mb-5 col-span-1">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "Solar Panel Image" | translate }}
                        </label>
                        <div class="flex flex-row flex-grow md:space-x-2">
                          <div
                            class="w-[100px] h-[100px] border border-solid border-gray-400 rounded-2xl bg-dark-gray-2 text-white gradient-header flex justify-center"
                          >
                            <img
                              [src]="
                                solarForm.controls['panelImage'].value
                                  ? solarForm.controls['panelImage'].value
                                  : '../../../../assets/svg/gallery.svg'
                              "
                              alt="Gallery"
                              [ngClass]="{
                                'w-[100px] h-[100px] rounded-2xl':
                                  solarForm.controls['panelImage'].value,
                                'm-8': !solarForm.controls['panelImage'].value
                              }"
                              (click)="
                                solarForm.controls['panelImage'].value
                                  ? displayImage(
                                      solarForm.controls['panelImage'].value
                                    )
                                  : null
                              "
                            />
                          </div>
                          <div
                            class="hidden w-1/4 border border-solid border-gray-400 rounded-2xl py-[8px] px-3 bg-dark-gray-2 text-white gradient-header flex justify-center items-center text-xs"
                          >
                            {{ "upload" | translate }}
                            <img
                              src="../../../../assets/svg/upload-fota.svg"
                              class="mx-1"
                              alt="Upload"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col mb-5 col-span-1">
                        <label
                          class="block text-white mb-2 opacity-80 text-xs gradient-header"
                          for="email"
                        >
                          {{ "Purchase Bill Image" | translate }}
                        </label>
                        <div class="flex flex-row flex-grow md:space-x-2">
                          <div
                            class="w-[100px] h-[100px] border border-solid border-gray-400 rounded-2xl bg-dark-gray-2 text-white gradient-header flex justify-center"
                          >
                            <img
                              [src]="
                                solarForm.controls['purchaseBillImage'].value
                                  ? solarForm.controls['purchaseBillImage']
                                      .value
                                  : '../../../../assets/svg/gallery.svg'
                              "
                              alt="Gallery"
                              [ngClass]="{
                                'w-[100px] h-[100px] rounded-2xl':
                                  solarForm.controls['purchaseBillImage'].value,
                                'm-8':
                                  !solarForm.controls['purchaseBillImage'].value
                              }"
                              (click)="
                                solarForm.controls['purchaseBillImage'].value
                                  ? displayImage(
                                      solarForm.controls['purchaseBillImage']
                                        .value
                                    )
                                  : null
                              "
                            />
                          </div>
                          <div
                            class="hidden w-1/4 border border-solid border-gray-400 rounded-2xl py-[8px] px-3 bg-dark-gray-2 text-white gradient-header flex justify-center items-center text-xs"
                          >
                            {{ "upload" | translate }}
                            <img
                              src="../../../../assets/svg/upload-fota.svg"
                              class="mx-1"
                              alt="Upload"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- fourth row -->
                      <div class="col-span-6">
                        <div class="border-b border-gray-200">
                          <div
                            (click)="toggleExpansion()"
                            class="w-full text-left focus:outline-none text-white cursor-default flex flex-row justify-between"
                          >
                            <div class="flex flex-row space-x-8">
                              <span>
                                Type of Solar Panels ({{
                                  solarForm.controls.panels.length
                                }})</span
                              >
                            </div>
                            <div>
                              <fa-icon
                                [icon]="expanded ? faChevronUp : faChevronDown"
                                class="text-white"
                              ></fa-icon>
                            </div>
                          </div>

                          <div
                            *ngIf="expanded"
                            class="text-white p-4 bg-[#323841] rounded-lg mt-5"
                            formArrayName="panels"
                          >
                            <div
                              *ngFor="
                                let panel of solarForm.controls.panels.controls;
                                let i = index
                              "
                              [formGroupName]="i"
                            >
                              <div class="mb-1">
                                <span>Type of Panels {{ 1 + i }}</span>
                              </div>
                              <div class="grid grid-cols-6 gap-x-5">
                                <!-- first row -->

                                <div class="flex flex-col mb-5 col-span-2">
                                  <label
                                    class="block text-white mb-2 opacity-80 text-xs gradient-header"
                                    for="email"
                                  >
                                    {{ "Brand" | translate }}
                                  </label>
                                  <div
                                    class="flex flex-row flex-grow md:space-x-3"
                                  >
                                    <input
                                      formControlName="brand"
                                      class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-[#323841] text-xs"
                                      id="email"
                                      type="email"
                                      placeholder="xxxxxxxxxxx"
                                    />
                                  </div>
                                </div>

                                <div class="flex flex-col mb-5 col-span-2">
                                  <label
                                    class="block text-white mb-2 opacity-80 text-xs gradient-header"
                                    for="email"
                                  >
                                    {{ "Type" | translate }}
                                  </label>
                                  <div class="flex flex-row flex-grow">
                                    <input
                                      [formControlName]="'panelType'"
                                      class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-[#323841] text-xs"
                                      id="email"
                                      type="email"
                                      placeholder="xxxxxxxxxxx"
                                    />
                                  </div>
                                </div>
                                <div class="flex flex-col mb-5 col-span-1">
                                  <label
                                    class="block text-white mb-2 opacity-80 text-xs gradient-header"
                                    for="email"
                                  >
                                    {{ "Number of Panel" | translate }}
                                  </label>
                                  <div
                                    class="flex flex-col md:flex-row flex-grow"
                                  >
                                    <input
                                      [formControlName]="'noofPanel'"
                                      class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-[#323841] text-xs"
                                      id="email"
                                      type="email"
                                      placeholder="xxxxxxxxxxx"
                                    />
                                  </div>
                                </div>
                                <div class="col-span-1"></div>
                                <div class="flex flex-col mb-5 col-span-1">
                                  <label
                                    class="block text-white mb-2 opacity-80 text-xs gradient-header"
                                    for="email"
                                  >
                                    {{ "Capacity(Watt)" | translate }}
                                  </label>
                                  <div
                                    class="flex flex-row flex-grow md:space-x-3"
                                  >
                                    <input
                                      [formControlName]="'capacity'"
                                      class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-[#323841] text-xs"
                                      id="email"
                                      type="email"
                                      placeholder="xxxxxxxxxxx"
                                    />
                                  </div>
                                </div>

                                <div class="flex flex-col mb-5 col-span-2">
                                  <label
                                    class="block text-white mb-2 opacity-80 text-xs gradient-header"
                                    for="email"
                                  >
                                    V<sub>oc </sub>
                                    {{ "Open Circuit Voltage " | translate }}
                                  </label>
                                  <div class="flex flex-row">
                                    <input
                                      [formControlName]="'openCircuitVoltage'"
                                      class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-[#323841] text-xs"
                                      id="email"
                                      type="email"
                                      placeholder="xxxxxxxxxxx"
                                    />
                                  </div>
                                </div>
                                <div class="flex flex-col mb-5 col-span-2">
                                  <label
                                    class="block text-white mb-2 opacity-80 text-xs gradient-header"
                                    for="email"
                                  >
                                    I<sub>oc </sub>
                                    {{ "Short Circuit Voltage " | translate }}
                                  </label>
                                  <div class="flex flex-row flex-grow">
                                    <input
                                      [formControlName]="'shortCircuitCurrent'"
                                      class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-[#323841] text-xs"
                                      id="email"
                                      type="email"
                                      placeholder="xxxxxxxxxxx"
                                    />
                                  </div>
                                </div>
                                <div class="col-span-1"></div>
                                <!-- 2nd row -->
                              </div>
                              <div
                                class="flex-wrap h-[0.3px] my-2 bg-slate-500 opacity-40 mx-4"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Sixth row -->
                      <div
                        class="hidden col-span-6 flex justify-st items-center"
                      >
                        <div
                          class="flex justify-center items-center rounded-xl bg-dark-accent-secondary px-4 py-[5px] text-white"
                        >
                          <span class="text-xs p-0">Save</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          }

          <div
            class="content"
            [ngClass]="{ active: activeTab === 'dataLoggerDetails' }"
          >
            <!-- Data Logger Details Content -->
            <form [formGroup]="dataLoggerForm">
              <div class="p-4">
                <div class="tab-content flex flex-col">
                  <div class="grid grid-cols-6 gap-x-5">
                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "serial_number" | translate }}
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-3"
                      >
                        <input
                          formControlName="serialNo"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>

                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "firmware_version" | translate }}
                      </label>
                      <div class="flex flex-col md:flex-row flex-grow">
                        <input
                          formControlName="firmWareVersion"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>
                    <div class="col-span-2"></div>

                    <!-- 2nd row -->
                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "connectivity_type" | translate }}
                      </label>
                      <div class="flex flex-row flex-grow md:space-x-3">
                        <input
                          formControlName="connectivityType"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>

                    <div class="flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "installation_date" | translate }}
                      </label>
                      <div class="flex flex-col md:flex-row flex-grow">
                        <input
                          formControlName="installationDate"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-btTh text-xs"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>
                    <div class="hidden flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "wifi_signal" | translate }}
                      </label>
                      <div class="flex flex-col md:flex-row flex-grow">
                        <input
                          formControlName="connectivityType"
                          class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-btTh"
                          id="email"
                          type="email"
                          placeholder="xxxxxxxxxxx"
                        />
                      </div>
                    </div>

                    <!-- third row -->

                    <div class="col-span-6 bg-[#2b3139] rounded-xl mb-6">
                      <div class="flex flex-col py-2">
                        <span
                          class="px-4 text-[#94989E] sm:text-[13px] xl:text-2xl"
                          >Today’s Signal Strength</span
                        >
                        <div class="h-60">
                          <div class="h-full flex flex-grow">
                            @if (chartOptions &&
                            chartOptions.chart?.type &&
                              chartOptions.series &&
                              chartOptions.series.length > 0
                            ) {
                              <apx-chart
                                class="w-full h-full"
                                [series]="chartOptions.series!"
                                [chart]="chartOptions.chart!"
                                [xaxis]="chartOptions.xaxis!"
                                [yaxis]="chartOptions.yaxis!"
                                [tooltip]="chartOptions.tooltip!"
                                [dataLabels]="chartOptions.dataLabels!"
                                [stroke]="chartOptions.stroke!"
                                [plotOptions]="chartOptions.plotOptions!"
                                [grid]="chartOptions.grid!"
                              ></apx-chart>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- fourth row -->

                    <div class="hidden flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-white mb-2 opacity-80 text-xs gradient-header"
                        for="email"
                      >
                        {{ "wifi_configuration" | translate }}
                      </label>
                    </div>

                    <div class="hidden flex flex-col mb-5 col-span-2">
                      <label
                        class="block text-blue-400 mb-2 opacity-80 text-xs m-0"
                        for="email"
                      >
                        Luminous_IOT_Wifi_xxxx
                      </label>
                      <div
                        class="flex flex-col md:flex-row flex-grow md:space-x-2"
                      >
                        <span class="text-xs opacity-80 gradient-header m-0">{{
                          "connected" | translate
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div
            class="content"
            [ngClass]="{ active: activeTab === 'connectedUser' }"
          >
            <!-- Connected User Content -->
            <div class="p-4">
              <div>
                <span class="dark:text-white text-sm opacity-40">{{
                  "primary_users" | translate
                }}</span>
                @if (connectedUsersData?.primaryUser) {
                  <div
                    class="flex flex-row justify-start items-center space-x-2 mt-4"
                  >
                    <img
                      src="../../../../assets/svg/default-user.svg"
                      alt="avator"
                      class="w-9 h-9"
                      alt="Avator"
                    />
                    <span class="dark:text-blue-500 text-[12px]">{{
                      connectedUsersData?.primaryUser?.userName
                    }}</span>
                  </div>
                }

                <hr class="my-4" />
              </div>
              <div>
                <span class="dark:text-white text-sm opacity-40">{{
                  "secondary_users" | translate
                }}</span>

                @if (connectedUsersData?.seocndaryUsers) {
                  @for (
                    item of connectedUsersData?.seocndaryUsers;
                    track $index
                  ) {
                    <div
                      class="flex flex-row justify-between items-center space-x-2 mt-4"
                    >
                      <div class="flex flex-row items-center space-x-2">
                        <img
                          src="../../../../assets/svg/default-user.svg"
                          class="w-9 h-9"
                          alt="Avator"
                        />
                        <div class="flex flex-col leading-tight">
                          <span class="dark:text-blue-500 text-[12px]">{{
                            item.userName
                          }}</span>
                          @if (isPrimaryUser) {
                            <span class="label-xs ml-0 text-white opacity-60">{{
                              item.email ?? ""
                            }}</span>
                          }
                        </div>
                      </div>
                      @if (isPrimaryUser) {
                        <div
                          class="rounded-xl px-3 py-[2px] text-white border border-solid border-gray-400 cursor-default"
                          (click)="removeUser(item)"
                        >
                          <span class="text-xs p-0">{{
                            "remove" | translate
                          }}</span>
                        </div>
                      }
                    </div>
                  }
                }
                <a
                  routerLinkActive="active"
                  [routerLink]="['../../add-secondary-user']"
                  [queryParams]="{ plantId: plantData?.id }"
                >
                  <div class="flex flex-col mt-5 col-span-2">
                    <div
                      class="flex flex-col md:flex-row flex-grow md:space-x-2"
                    >
                      @if (isPrimaryUser) {
                        <div
                          class="border border-solid border-gray-400 rounded-2xl py-[8px] px-3 text-white gradient-header bg-dark-gray-2 flex justify-center items-center"
                        >
                          <div
                            class="rounded-full bg-blue-500 mr-2 w-5 h-5 flex justify-center items-center"
                          >
                            <fa-icon
                              [icon]="faPlus"
                              class="color-white"
                            ></fa-icon>
                          </div>
                          {{ "Add User" | translate }}
                        </div>
                      }
                    </div>
                  </div></a
                >
              </div>
            </div>
          </div>
          @if (isPrimaryUser) {
            <div
              class="content"
              [ngClass]="{ active: activeTab === 'downloadReport' }"
            >
              <!-- Connected User Content -->
              <div class="p-4">
                <div class="flex justify-center items-center mt-20">
                  <div class="w-full h-full">
                    <form [formGroup]="radioForm">
                      <div class="grid grid-cols-3 grid-rows-2 gap-y-14">
                        <div
                          *ngFor="let option of options"
                          class="col-span-1 justify-center items-center"
                        >
                          <input
                            type="radio"
                            [value]="option"
                            formControlName="selectedOption"
                          />
                          <span class="text-white text-sm ms-2">{{
                            option.value
                          }}</span>
                        </div>
                      </div>
                    </form>
                    <div class="flex justify-center items-center mt-32">
                      <div
                        class="flex justify-center items-center rounded-xl bg-dark-accent-secondary hover:opacity-80 px-4 py-[10px] text-white cursor-default"
                        (click)="downloadReport()"
                      >
                        <span class="text-xs p-0">{{
                          "send_to_email" | translate
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
