@if (tickets) {
  <div
    class="max-h-[90%] p-x-[5%] overflow-y-auto overflow-x-hidden justify-center"
  >
    <table class="w-full">
      <thead class="bg-btTh sticky p-0">
        <tr>
          @for (item of tableKeys; track $index) {
            @if (item == "ticketid") {
              <th class="label-xs font-thin whitespace-nowrap w-[17%]">
                {{ headerKeyMap[item] }}
              </th>
            }
            @if (item == "subject") {
              <th
                class="label-xs font-thin whitespace-nowrap text-wrap w-[25%]"
              >
                {{ headerKeyMap[item] }}
              </th>
            }
            @if (item == "logdate") {
              <th class="label-xs font-thin whitespace-nowrap w-[18%]">
                {{ headerKeyMap[item] }}
              </th>
            }
            @if (item == "priority") {
              <th class="label-xs font-thin whitespace-nowrap w-[6%]">
                {{ headerKeyMap[item] }}
              </th>
            }
            @if (item == "assignee") {
              <th class="label-xs font-thin whitespace-nowrap w-[12%]">
                {{ headerKeyMap[item] }}
              </th>
            }
            @if (item == "currentStatus") {
              <th class="label-xs font-thin whitespace-nowrap w-[10%]">
                {{ headerKeyMap[item] }}
              </th>
            }
            @if (item == "action") {
              <th class="label-xs font-thin whitespace-nowrap w-[10%]">
                {{ headerKeyMap[item] }}
              </th>
            }
          }
        </tr>
      </thead>
      <tbody>
        @for (item of tickets; track $index) {
          <tr>
            <td>{{ item.ticketId || "	-  -" }}</td>
            <td class="text-start text-wrap">
              {{ item.subject || "	-  -" }}
            </td>
            <td>{{ item.logdate || "  -  -" }}</td>
            <td>{{ item.priority || "	-	 -" }}</td>
            <td>{{ item.assignee || "	-  -" }}</td>
            <td>{{ item.status || "- 	-" }}</td>
            <td>
              <div class="flex justify-center">
                <div>
                  <div
                    (click)="sideSheet.toggle()"
                    (click)="sideSheet.viewTicket(item.ticketId)"
                    class="cursor-pointer"
                  >
                    View
                  </div>
                </div>
                <div class="ml-[5%] mr-[5%]">|</div>
                <div>
                  <div
                    (click)="editform.toggle()"
                    (click)="
                      editform.openFormEditable(item.ticketId, true, item.id)
                    "
                    class="cursor-pointer"
                  >
                    Edit
                  </div>
                </div>
              </div>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
} @else {
  <div class="text-center flex h-[100%] w-[100%] items-center justify-center">
    <span> No Record Found</span>
  </div>
}

<div>
  <app-viewticket></app-viewticket>
  <app-ticket-dialog></app-ticket-dialog>
</div>
