import { Injectable } from '@angular/core';
import { BaseUserService } from '../../base/services/base-user.service';
import { AnalyticsDetails } from '../../shared/models/analytics-details';
import { Observable, of } from 'rxjs';
import { GraphData } from '../../shared/models/graph-data';
import { Assignee } from '../../shared/models/assignee-response';
import {
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { CreateTicket } from '../../shared/models/create-ticket';
import { USER_KEY } from '../../shared/service/storage.service';

import { AnalyticsTicketHistory } from '../../shared/models/analytics-ticket-history';
import { ViewTicket } from '../../shared/models/view-ticket';
import {
  UpdateTicket,
  UpdateTicketDetail,
} from '../../shared/models/update-ticket';
@Injectable({
  providedIn: 'root',
})
export class CustomerService extends BaseUserService {
  baseUrl!: string;
  selectedFile: File | null = {
    name: 'sample-image.png',
    lastModified: new Date().getTime(),
    size: 1024, // size in bytes
    type: 'image/png',
    webkitRelativePath: '',
    slice: (start, end, contentType) =>
      new Blob([], { type: contentType || 'image/png' }),
    arrayBuffer: () => Promise.resolve(new ArrayBuffer(1024)),
    stream: () => new ReadableStream(),
    text: () => Promise.resolve(''),
  };
  getAnalyticsDetails(deviceId: string): Observable<AnalyticsDetails> {
    let url =
      this.env.externalUrls.data_analytics.baseUrl +
      `externalB2B/api/getAnalyticsDetails?deviceId=${deviceId}`;
    return this.http.get<AnalyticsDetails>(url);
  }
  getGraphData(deviceId: string): Observable<GraphData> {
    let url =
      this.env.externalUrls.data_analytics.baseUrl +
      `externalB2B/api/getSeriesAnalyticsData?deviceId=${deviceId}`;
    return this.http.get<GraphData>(url);
  }
  getAssigneeDetails(siUserId: string | null): Observable<Assignee> {
    let url =
      this.env.externalUrls.user.baseUrl +
      `users/nox/getSiSubUsers?siUserId=${siUserId}`;
    return this.http.get<Assignee>(url);
  }

  uploadImage(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const queryParams = new URLSearchParams({
      tag: 'plants',
      metatag: 'ticketlog',
      userId: '3f7a1cc7-aa84-40ba-ba1c-c7aa8490baef',
    }).toString();

    const req = new HttpRequest(
      'POST',
      `https://lumconnectupload.azurewebsites.net/api/v1/upload/image?${queryParams}`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      },
    );
    return this.http.request(req).pipe(
      switchMap((event) => {
        if (event.type === HttpEventType.Response) {
          return of((event as HttpResponse<any>).body);
        } else {
          return of(null);
        }
      }),
      map((response) => response),
    );
  }

  createTicket(form: CreateTicket): Observable<CreateTicket> {
    let url =
      this.env.externalUrls.system_integration.baseUrl + '/createTicket';
    return this.http.post<CreateTicket>(url, form);
  }
  getTicketHistory(): Observable<AnalyticsTicketHistory> {
    const queryParams = new URLSearchParams({
      plantId: localStorage.getItem('pId') ?? '',
      siUserId: this.storageService.getObject(USER_KEY).id,
    }).toString();
    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/getTicketHistoryByPlantId?${queryParams}`;
    return this.http.get<AnalyticsTicketHistory>(url);
  }
  viewTicket(id: string): Observable<ViewTicket> {
    const queryParams = new URLSearchParams({
      ticketId: id,
    }).toString();
    let url =
      this.env.externalUrls.system_integration.baseUrl +
      `/viewTicket?${queryParams}`;
    return this.http.get<ViewTicket>(url);
  }
  updateTicket(updatedform: UpdateTicketDetail): Observable<UpdateTicket> {
    let url =
      this.env.externalUrls.system_integration.baseUrl + `/updateTicket`;
    return this.http.put<UpdateTicket>(url, updatedform);
  }
  deleteImage(imageUrl: any) {
    const queryParams = new URLSearchParams({
      url: imageUrl,
    });
    let url = `https://lumconnectupload.azurewebsites.net/api/v1/delete/image?${queryParams}`;
    return this.http.delete(url);
  }
}
