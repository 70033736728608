<div
  [class.open]="isOpen"
  (click)="closeLargeImage()"
  class="side-sheet fixed top-[19%] p-6 bottom-0 h-[38.4rem] w-[68%] z-[1000] right-[-100%] bg-[#22282F] rounded-lg gradient-border"
>
  <div class="flex flex-col w-[100%] h-full p-2">
    <div class="w-[100%] h-[100%] overflow-auto p-1">
      <div class="mb-[1.3%]">
        <h2 class="text-white text-xl font-semibold">
          {{ plantName }}
        </h2>
      </div>
      <div>
        <form [formGroup]="ticketForm" id="uploadForm">
          <div class="grid grid-cols-4 gap-7 w-[100%] mb-[2%]">
            <div>
              <label
                for="category"
                class="block text-gray-400 text-sm ml-[0.5%]"
                >Category</label
              >

              <select
                id="category"
                formControlName="category"
                class="w-full box-border border-[0.1px] mt-[4%] border-gray-400 rounded-2xl py-[8px] px-4 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              >
                <option value="" disabled>Select</option>
                <option *ngFor="let option of categories" [value]="option">
                  {{ option }}
                </option>
              </select>
              <div
                *ngIf="
                  ticketForm.get('category')?.touched &&
                  ticketForm.get('category')?.invalid
                "
                class="text-red-500 text-xs ml-[3%] mt-1"
              >
                This field is required.
              </div>
            </div>

            <div>
              <label for="assignee" class="block text-gray-400 text-sm"
                >Assignee</label
              >

              <select
                id="assignee"
                formControlName="assignee"
                class="w-full box-border border-[0.1px] mt-[4%] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              >
                <option value="" disabled>Select</option>
                <option *ngFor="let option of assignees" [value]="option">
                  {{ option }}
                </option>
              </select>
              <div
                *ngIf="
                  ticketForm.get('assignee')?.touched &&
                  ticketForm.get('assignee')?.invalid
                "
                class="text-red-500 text-xs ml-[3%] mt-1"
              >
                This field is required.
              </div>
            </div>

            <div>
              <label for="priority" class="block text-gray-400 text-sm"
                >Priority</label
              >

              <select
                id="Priority"
                formControlName="priority"
                class="w-full box-border border-[0.1px] border-gray-400 rounded-2xl mt-[4%] py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              >
                <option value="" disabled>Select</option>
                <option *ngFor="let option of priorities" [value]="option">
                  {{ option }}
                </option>
              </select>
              <div
                *ngIf="
                  ticketForm.get('priority')?.touched &&
                  ticketForm.get('priority')?.invalid
                "
                class="text-red-500 text-xs ml-[3%] mt-1"
              >
                This field is required.
              </div>
            </div>

            <div>
              <label for="ticketStatus" class="block text-gray-400 text-sm"
                >Ticket Status</label
              >

              <select
                id="ticketStatus"
                formControlName="ticketStatus"
                class="w-full box-border border-[0.1px] border-gray-400 mt-[4%] rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              >
                <option value="" disabled>Select</option>
                <option *ngFor="let option of ticketStatus" [value]="option">
                  {{ option }}
                </option>
              </select>
              <div
                *ngIf="
                  ticketForm.get('ticketStatus')?.touched &&
                  ticketForm.get('ticketStatus')?.invalid
                "
                class="text-red-500 text-xs ml-[3%] mt-1"
              >
                This field is required.
              </div>
            </div>
          </div>

          <div class="mb-[2%] w-[100%]">
            <label for="subject" class="block text-gray-400 text-sm"
              >Subject</label
            >

            <input
              type="text"
              id="subject"
              formControlName="subject"
              placeholder="Add subject"
              class="w-[48.5%] box-border border-[0.1px] mt-[1.5%] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
            />
            <div
              *ngIf="
                ticketForm.get('subject')?.touched &&
                ticketForm.get('subject')?.errors
              "
            >
              <div
                *ngIf="ticketForm.get('subject')?.hasError('required')"
                class="text-red-500 text-xs ml-2 mt-2"
              >
                This field is required.
              </div>
              <div
                *ngIf="ticketForm.get('subject')?.hasError('minlength')"
                class="text-red-500 text-xs ml-2 mt-2"
              >
                Minimum 25 characters required.
              </div>
              <div
                *ngIf="ticketForm.get('subject')?.hasError('maxlength')"
                class="text-red-500 text-xs ml-2 mt-2"
              >
                Maximum 100 characters allowed.
              </div>
            </div>
          </div>

          <div class="w-[100%] max-h-fit flex mb-[2%] h-[14rem]">
            <div class="w-[48%] h-full">
              <label
                for="description"
                class="block resize-none text-gray-400 text-sm"
                >Description</label
              >

              <textarea
                id="description"
                formControlName="description"
                rows="10"
                placeholder="Add description"
                class="w-[100%] h-[75%] mt-[3%] box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              ></textarea>
              <div
                *ngIf="
                  ticketForm.get('description')?.touched &&
                  ticketForm.get('description')?.invalid
                "
                class="text-red-500"
              >
                This field is required
              </div>
            </div>
            <div class="flex ml-1">
              <div class="flex flex-col h-full ml-[3%]">
                <div
                  class="flex"
                  [class.mtfirst]="imageArrayLength == 1"
                  [class.mtsec]="imageArrayLength == 2"
                  [class.mtthird]="imageArrayLength == 3"
                  [class.mtfourth]="imageArrayLength == 4"
                >
                  <div class="ml-[1.5%] flex flex-col h-fit">
                    @for (image of images; track $index) {
                      <div class="flex h-fit p-1.5">
                        <div
                          class="flex"
                          (click)="showLargeImg($index, $event)"
                        >
                          <div
                            class="w-fit h-fit mr-4 border-white imgborder rounded-md"
                          >
                            <img
                              [src]="image.preview"
                              alt="Thumbnail"
                              class="w-6 h-6 object-cover"
                            />
                          </div>

                          <div class="text-wrap w-[15rem]">
                            <span class="text-sm text-gray-400">{{
                              image.file?.name
                            }}</span>
                          </div>
                        </div>

                        <div (click)="imageDelete($index)" class="ml-2">
                          <img
                            src="../../../../../assets/images/deleteicon (2).svg"
                            class="h-6 w-6"
                          />
                        </div>
                      </div>
                      @if ($index < images.length - 1) {
                        <div
                          class="w-[22rem] h-[0.01rem] p-[0.2%] bg-white"
                        ></div>
                      }
                    }
                  </div>
                </div>

                @if (imageArrayLength < 4) {
                  <div
                    class="bg-[#22282F] pt-1"
                    [class.lzero]="imageArrayLength == 0"
                  >
                    <input
                      type="file"
                      id="file"
                      class="file-input hidden"
                      accept=".jpeg,.png"
                      (change)="onFileSelected($event)"
                    />
                    <div
                      class="file-input-label rounded-full flex items-center justify-center cursor-pointer"
                      (click)="triggerFileInput()"
                    >
                      <img
                        src="../../../../../assets/images/uploadimgicon.svg"
                        class="file-icon"
                        alt="Upload"
                      />
                      <span>Upload Image</span>
                    </div>
                  </div>
                }
              </div>

              @if (largeImage) {
                <div class="h-fit w-fit ml-7 mt-20">
                  <img [src]="largeImage" class="h-24 w-24" />
                </div>
              }
            </div>
          </div>
          <div class="mb-[2%] w-[48%] h-[14rem] max-h-16" id="textarea">
            <textarea
              id="commentbox"
              formControlName="comment"
              placeholder="Add Comment"
              rows="2"
              class="box-border border-[0.1px] border-gray-400 rounded-2xl py-[8px] px-3 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
            ></textarea>

            <div
              *ngIf="
                ticketForm.get('comment')?.touched &&
                ticketForm.get('comment')?.invalid
              "
              class="text-red-500 text-xs ml-[2.5%]"
            >
              This field is required.
            </div>
          </div>

          <div class="mt-[40%] flex h-fit" id="buttons">
            <div class="h-fit" (click)="submitFormData()">
              <button
                type="submit"
                [disabled]="ticketForm.invalid"
                class="box-borde border-[0.1px] h-6 border-slate-100 rounded-2xl py-[8px] px-5 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              >
                Save
              </button>
            </div>
            <div class="h-fit ml-2">
              <button
                type="button"
                (click)="toggle()"
                class="box-border ml-[2%] h-6 border-[0.1px] border-slate-100 rounded-2xl py-[8px] px-5 text-black dark:text-white leading-tight bg-dark-gray-2 text-xs"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isOpen" class="overlay" (click)="toggle()"></div>
